import { AccordionType } from "../../../entities/accordions/AccordionType";
import BaseButton from "../../atoms/buttons/BaseButton";
import Caret from "../../atoms/icons/Caret";

const Accordion = (props: AccordionType) => {
  return (
    <div className={`w-full ${props.className}`}>
      <h2 id="accordion-collapse-heading-1">
        <BaseButton
          id={props.id}
          className="flex justify-between p-5 pb-4 w-full font-medium text-gray-500 dark:text-gray-400 border-transparent"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-1"
          onClick={props.onClick}
        >
          <span className={`${props.textPosition === "center" ? "m-auto" : ""}`}>{props.text}</span>
          <Caret open={props.open} initialDirection={"down"} />
        </BaseButton>
      </h2>
      <div id="accordion-collapse-body-1" className={`px-10 ${props.open ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-1">
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
