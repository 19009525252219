import { t } from "i18next";
import { VEHICLE_MAKE, VEHICLE_MODEL, VEHICLE_YEAR, VEHICLE_VIN, ODOMETER } from "../../constants/constants";
import { VehicleInfoFieldsType } from "../../entities/vehicle-info/VehicleInfoFieldsType";

export const VehicleInfoInitialFields: VehicleInfoFieldsType[] = [
  {
    errorMessage: "",
    labelText: t("VEHICLE_MAKE"),
    name: VEHICLE_MAKE,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("VEHICLE_MODEL"),
    name: VEHICLE_MODEL,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("VEHICLE_YEAR"),
    name: VEHICLE_YEAR,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("VEHICLE_VIN"),
    name: VEHICLE_VIN,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("VEHICLE_MILEAGE"),
    name: ODOMETER,
    value: ""
  }
];
