import { TextInputType } from "../../../entities/inputs/TextInputType";
import BaseInput from "../../atoms/inputs/BaseInput";
import BaseLabel from "../../atoms/labels/BaseLabel";
import ErrorLine from "../../atoms/typography/ErrorLine";

/**
 * Text input with label component
 *
 */
const TextInput = (props: TextInputType) => {
  return (
    <div className={props.divClasses}>
      <BaseLabel
        for={props.id}
        labelText={props.labelText}
        className={`${props.labelClasses} text-center block text-base text-sky-500 font-light my-2`}
        icon={props.icon}
        iconPosition={props.iconPosition}
      />
      <BaseInput
        id={props.id}
        name={props.name}
        className={`${props.inputClasses} block border-2 border-gray-200 rounded-md w-56 md:w-64 text-base form-input m-auto mb-2`}
        onChange={props.onChange}
        type={"text"}
        value={props.value}
      />
      <ErrorLine errorMsg={props.errorMsg || ""} />
    </div>
  );
};

export default TextInput;
