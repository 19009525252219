import { BaseButtonType } from "../../../entities/buttons/BaseButtonType";
import BaseButton from "./BaseButton";

/**
 * Navigate to previous page button in Gray gradient by default
 * 
 * @returns Button JSX element
 */

const BackwardButton = (props: BaseButtonType) => {
  return (
    <BaseButton
      onClick={props.onClick}
      className={`bg-gradient-to-b from-gray-500 to-gray-400 hover:bg-gradient-to-t text-white text-base font-bold py-2 px-4 rounded min-w-min min-w-[7rem] ${props.className}`}
      value={props.value}
    >
      {props.children}
    </BaseButton>
  );
};

export default BackwardButton;
