import { ChangeEvent, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { EmailInputType } from "../../../entities/inputs/EmailInputType";
import { validateEmailUtil } from "../../../utils/validate-email/ValidateEmailUtil";
import BaseInput from "../../atoms/inputs/BaseInput";
import BaseLabel from "../../atoms/labels/BaseLabel";
import ErrorLine from "../../atoms/typography/ErrorLine";

/**
 * If props.validateEmail is true, email input will be validated
 *
 * The email input will be revalidated when there is no more text changing after 1.5 seconds (debounce feature)
 *
 */
const EmailInput = ({ required = true, ...props }: EmailInputType) => {
  const [validatorMsg, setValidatorMsg] = useState<string>("");

  // validate input after 1 seconds of stopped typing
  const debounced = useDebouncedCallback((email: string) => {
    const msg = validateEmailUtil(email, required);
    setValidatorMsg(msg);

    props.hasError && props.hasError(msg !== "");
  }, 1000);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    // onChange function from parent component
    props.onChange(e);

    if (props.validateEmail) {
      debounced(e.target.value);
    } else {
      props.hasError && props.hasError(false);
    }
  };

  return (
    <div className={props.divClasses}>
      <BaseLabel for={props.id} labelText={props.labelText} className={`${props.labelClasses} block text-base text-sky-500 font-light my-2`} />
      <BaseInput
        id={props.id}
        name={props.name}
        className={`${props.inputClasses} block border-2 border-gray-200 rounded-md w-56 md:w-64 text-sm mb-2`}
        onChange={handleOnChange}
        type={"email"}
        value={props.value}
        disabled={props.disabled}
      />
      <ErrorLine className="text-center" errorMsg={props.errorMsg || validatorMsg} />
    </div>
  );
};

export default EmailInput;
