import { PredefinedButtonType } from "../../../entities/buttons/PredefinedButtonType";
import BaseButton from "./BaseButton";

/**
 * Defined buttons
 * 1) Blue gradient
 * 2) Gray gradient
 * 3) Blueish
 *
 * @returns Button JSX element
 */
const PredefinedButton = (props: PredefinedButtonType) => {
  return (
    <BaseButton
      className={`text-white text-base font-bold px-4 ${props.className}
      ${props.corner === "rounded" ? "rounded" : props.corner === "pill" ? "rounded-full" : ""}
        ${
          props.variant === "blueGradient"
            ? "bg-gradient-to-b from-sky-500 to-sky-400 hover:bg-gradient-to-t"
            : props.variant === "grayGradient"
            ? "bg-gradient-to-b from-gray-500 to-gray-400 hover:bg-gradient-to-t"
            : props.variant === "blueish"
            ? "py-2 text-base font-semibold text-blue-800 bg-blue-100 hover:bg-blue-200 hover:text-blue-900"
            : ""
        }
        `}
      onClick={props.onClick}
      value={props.value}
    >
      {props.children}
    </BaseButton>
  );
};

export default PredefinedButton;
