import { t } from "i18next"

export const validatePasswordUtil = (password: string, required: boolean) => {
  // Password must be more than 7 characters
  if(password.length < 8 && password.length !== 0) {
    return t("MIN_EIGHT_CHARACTERS")
  }

  // Password cannot be more than 64 characters
  if(password.length > 64) {
    return t("MAX_SIXTY_FOUR_CHARACTERS")
  }

  if(password.length === 0 && required) {
    return t("REQUIRED")
  }

  return ""
}