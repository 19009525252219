// import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "./index.css";
import "animate.css";
import App from "./App";
import Header from "./components/molecules/header/Header";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Stepper from "./components/molecules/stepper/Stepper";
import SectionHeader from "./components/molecules/section-header/SectionHeader";
import Footer from "./components/molecules/footer/Footer";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { appPersistor, appStore } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={appPersistor}>
        <BrowserRouter>
          <Header />
          <Stepper steps={5} row_position="center" />
          <SectionHeader />
          <App />
          <Footer />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
