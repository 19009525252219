import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

const TextLink = ({ destination, children, onClick }: { destination: string; children: string; onClick?: MouseEventHandler<HTMLAnchorElement> }) => (
  <Link to={destination} onClick={onClick} className="text-sky-500 hover:text-gray-700">
    {children}
  </Link>
);

export default TextLink;
