import { t } from "i18next";
import { I_CANT_FIND_IT, NO, YES } from "../../constants/constants";
import { ChoiceObjectType } from "../../entities/buttons/MultipleChoiceButtonsType";

export const TitlePresentInitialFields: ChoiceObjectType[] = [
  {
    displayedText: t("YES"),
    value: YES
  },
  {
    displayedText: t("NO"),
    value: NO
  },
  {
    displayedText: t("I_CANT_FIND_IT"),
    value: I_CANT_FIND_IT
  }
];
