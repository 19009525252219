import { BaseLabelType } from "../../../entities/labels/BaseLabelType";

/**
 * Basic label element
 * 
 * @returns Label JSX element
 */
const BaseLabel = (props: BaseLabelType) => {
  return (
    <div className={`${props.iconPosition === "right" ? "flex justify-center" : ""}`}>
      <label htmlFor={props.for} className={`${props.className} ${props.labelText ? "" : "hidden"}`}>
        {props.labelText}
      </label>
      {props.icon}
    </div>
  );
}

export default BaseLabel;