import { ChooseFileItemType } from "../../../entities/choose-file-item/ChooseFileItem";
import FileInput from "../inputs/FileInput";

import "./ChooseFileItem.css";

const ChooseFileItem = (props: ChooseFileItemType) => {
  return (
    <div className="columns-1 md:columns-2 border-b-2 border-grey-300 border-double pb-3 mb-4">
      <FileInput errorMsg={props.errorMsg} name={props.name} onChange={props.onChange} for={props.name} labelText={props.labelText} />
    </div>
  );
};

export default ChooseFileItem;
