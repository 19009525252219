export enum StepperOptions {
  CURRENT,
  DONE,
  NOT_STARTED,
  SKIPPED
}

export type StepperStateType = {
  status: StepperOptions[] | [];
};
