export const ACCOUNT_NUMBER = "account_number";
export const CAR_REGISTRATION = "car_registration";
export const CUST_CITY = "cust_city";
export const CUST_EMAIL = "cust_email";
export const CUST_FIRST_NAME = "cust_first_name";
export const CUST_LAST_NAME = "cust_last_name";
export const CUST_PHONE = "cust_phone";
export const CUST_STATE = "cust_state";
export const CUST_STREET = "cust_street_address";
export const CUST_ZIP = "cust_zip";
export const DEVELOPMENT = "development";
export const DRIVER_LICENSE = "driver_license";
export const FINANCIAL_INSTITUTION = "lease_loan_source";
export const IM_NOT_SURE = "I'm not sure";
export const I_CANT_FIND_IT = "I can't find it";
export const LOAN_OR_LEASE = "loan_lease";
export const LOAN = "loan";
export const LEASE = "lease";
export const MISCELLANEOUS_DOCUMENT = "miscellaneous_document";
export const MONTHLY_PAYMENT = "monthly_payment";
export const MULTIPLE_OWNERS = "multiple_owners";
export const NO = "no";
export const ODOMETER = "odometer";
export const ODOMETER_IMAGE = "odometer_image";
export const OWNER_AVAILABILITY = "all_parties_available";
export const OWN_VEHICLE = "vehicle_ownership";
export const PRODUCTION = "production";
export const PURCHASE_PRICE = "purchase_price";
export const REMAINING_BALANCE = "remaining_balance";
export const SECOND_OWNER_ADDRESS = "cust_2_street_address";
export const SECOND_OWNER_CITY = "cust_2_city";
export const SECOND_OWNER_EMAIL = "cust_2_email";
export const SECOND_OWNER_FIRST_NAME = "cust_2_first_name";
export const SECOND_OWNER_LAST_NAME = "cust_2_last_name";
export const SECOND_OWNER_PHONE = "cust_2_phone";
export const SECOND_OWNER_STATE = "cust_2_state";
export const SECOND_OWNER_ZIP = "cust_2_zip";
export const SWICKARD_LOGO = "swickard_logo";
export const TENDAY_PAYOFF_LETTER = "tenday_payoff_letter";
export const TEN_DAY_PAYOFF_AMOUNT = "tendaypayoff_amount";
export const THIRD_OWNER = "third_owner";
export const THIRD_OWNER_ADDRESS = "cust_3_street_address";
export const THIRD_OWNER_CITY = "cust_3_city";
export const THIRD_OWNER_EMAIL = "cust_3_email";
export const THIRD_OWNER_FIRST_NAME = "cust_3_first_name";
export const THIRD_OWNER_LAST_NAME = "cust_3_last_name";
export const THIRD_OWNER_PHONE = "cust_3_phone";
export const THIRD_OWNER_STATE = "cust_3_state";
export const THIRD_OWNER_ZIP = "cust_3_zip";
export const TITLE_DATE_ISSUED = "title_date_issued";
export const TITLE_IMAGE = "title_image";
export const TITLE_NUMBER = "title_number";
export const TITLE_PRESENT = "title_present";
export const VEHICLE_BODY = "vehicle_body";
export const VEHICLE_MAKE = "vehicle_make";
export const VEHICLE_MILEAGE = "vehicle_mileage";
export const VEHICLE_MODEL = "vehicle_model";
export const VEHICLE_VIN = "vin";
export const VEHICLE_YEAR = "vehicle_year";
export const YES = "yes";
export const REGISTRATION_EMAIL = "registration_email";

// Redux Action
export const RESET_STEPS = "RESET_STEPS";
export const UPDATE_STEPS = "UPDATE_STEPS";
export const UPDATE_SECTION_HEADER = "UPDATE_SECTION_HEADER";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const LOGOUT = "LOGOUT"

// Step numbers
export const STEP_CUSTOMER_INFO = 0;
export const STEP_VEHICLE_INFO = 1;
export const STEP_TITLE_PAGE = 2;
export const STEP_LOAN_LEASE = 3;
export const STEP_UPLOAD_DOCUMENT = 4;

// Routes
export const ROUTE_CUSTOMER_INFO = "/customer";
export const ROUTE_DOCUMENTS = "/documents";
export const ROUTE_LOANLEASE = "/loanlease";
export const ROUTE_SUCCESS = "/success";
export const ROUTE_TITLE = "/title";
export const ROUTE_VEHICLE = "/vehicle";
export const ROUTE_REGISTER = "/register";
export const ROUTE_UNAUTHORIZED = "/unauthorized"
export const ROUTE_LOGIN = "/login"
export const ROUTE_RESET_PASSWORD = "/reset-password"
export const ROUTE_GETTING_STARTED = "/getting-started"
export const ROUTE_PREVIOUS_PAGE = -1;
