import axios, { AxiosRequestConfig } from "axios";

import { appStore } from "../redux/store";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "",
  timeout: 1000
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authStatus = appStore.getState().authReducer;

    if (config.headers !== undefined) {
      config.headers["access-token"] = authStatus.accessToken;
    }

    return config;
  },
  (error) => Promise.resolve(error)
);

export const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "",
  timeout: 1000
});
