import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ForwardBackwardButton from "../../components/molecules/buttons/ForwardBackwardButtons";
import TextInput from "../../components/molecules/inputs/TextInput";
import { ROUTE_CUSTOMER_INFO, ROUTE_TITLE, STEP_CUSTOMER_INFO, STEP_TITLE_PAGE, STEP_VEHICLE_INFO } from "../../constants/constants";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { VehicleInfoFieldsType } from "../../entities/vehicle-info/VehicleInfoFieldsType";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";
import { VehicleInfoInitialFields } from "./VehicleInfoInitialFields";

const VehicleInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  const [vehicleInfoFields, setVehicleInfoFields] = useState<VehicleInfoFieldsType[]>(VehicleInfoInitialFields);

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("YOUR_INFORMATION"), subTitle: t("CUSTOMER_CONFIRM") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVehicleInfoFields = vehicleInfoFields.map((field: VehicleInfoFieldsType) =>
      field.name === e.currentTarget.name ? { ...field, value: e.currentTarget.value } : field
    );
    setVehicleInfoFields(newVehicleInfoFields);
  };

  const handleNextClick = () => {
    vehicleInfoFields.map((field: VehicleInfoFieldsType) => localStorage.setItem(field.name, field.value));
    const newStatus = stepperStatus.map((status, index) => {
      if (index === STEP_VEHICLE_INFO) {
        return StepperOptions.DONE;
      }
      if (index === STEP_TITLE_PAGE) {
        return StepperOptions.CURRENT;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: newStatus }));
    navigate(ROUTE_TITLE);
  };

  const handlePreviousClick = () => {
    const previousStatus = stepperStatus.map((status, index) => {
      if (index === STEP_CUSTOMER_INFO) {
        return StepperOptions.CURRENT;
      }
      if (index === STEP_VEHICLE_INFO) {
        return StepperOptions.NOT_STARTED;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: previousStatus }));
    navigate(ROUTE_CUSTOMER_INFO);
  };

  //make, model, year, vin, mileage,
  return (
    <div className="items-center flex flex-col">
      {vehicleInfoFields.map((field: VehicleInfoFieldsType) => (
        <TextInput
          key={field.name}
          labelText={field.labelText}
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={handleInputChange}
          errorMsg={""}
        />
      ))}

      <ForwardBackwardButton
        backwardText={t("PREVIOUS")}
        forwardText={t("NEXT")}
        backwardOnClick={handlePreviousClick}
        forwardOnClick={handleNextClick}
      />
    </div>
  );
};

export default VehicleInfo;
