import { BaseButtonType } from "../../../entities/buttons/BaseButtonType";

/**
 * Basic button element
 * 
 * @returns Button JSX element
 */
const BaseButton = (props: BaseButtonType) => {
  return (
    <button name={props.name} className={props.className} style={props.style} value={props.value} {...props} type="button">
      {props.children}
    </button>
  );
};

export default BaseButton;
