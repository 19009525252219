import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AlertTriangle from "../../components/atoms/icons/AlertTriangle";
import HelperIcon from "../../components/atoms/icons/HelperIcon";
import ForwardBackwardButton from "../../components/molecules/buttons/ForwardBackwardButtons";
import MultipleChoiceButtons from "../../components/molecules/buttons/MultipleChoiceButtons";
import TextInput from "../../components/molecules/inputs/TextInput";
import Alert from "../../components/molecules/notifications/Alert";
import { ROUTE_DOCUMENTS, ROUTE_TITLE, STEP_LOAN_LEASE, STEP_TITLE_PAGE, STEP_UPLOAD_DOCUMENT, STEP_VEHICLE_INFO, VEHICLE_MAKE } from "../../constants/constants";
import { LoanLeaseDetailFieldsType } from "../../entities/loan-lease-page/LoanLeaseDetailFieldsType";
import { LoanLeaseType } from "../../entities/loan-lease-page/LoanLeaseType";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";
import { LoanLeaseDetailInitialFields } from "./LoanLeaseDetailInitialFields";
import { LoanLeaseInitialFields } from "./LoanLeaseInitialFields";

const LoanLease = () => {
  const [loanLeaseInfo, setLoanLeaseInfo] = useState<LoanLeaseType>({
    loanOrLease: ""
  });

  const [loanLeaseDetailsInfo, setLoanLeaseDetailsInfo] = useState<LoanLeaseDetailFieldsType[]>(LoanLeaseDetailInitialFields);

  const [showTenDayHelperPopUp, setShowTenDayHelperPopUp] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const brandsArray = [
    "acura",
    "alfa romeo",
    "buick",
    "cadillac",
    "chevrolet",
    "chysler",
    "dodge",
    "fiat",
    "ford",
    "gmc",
    "genesis",
    "hyundai",
    "infiniti",
    "jeep",
    "kia",
    "lincoln",
    "mazda",
    "mini",
    "mistubishi",
    "nissan",
    "porsche",
    "ram",
    "saab",
    "scion",
    "smart",
    "subaru",
    "tesla",
    "volkswagen",
    "volvo"
  ];

  const handleTenDayHelperClick = () => {
    setShowTenDayHelperPopUp(!showTenDayHelperPopUp);
  };

  const closePopUp = () => {
    setShowTenDayHelperPopUp(false);
  };

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("FINANCING_TITLE"), subTitle: t("LOAN_OR_LEASE_QUESTION") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // && !brandsArray.includes()
  const vehicleMake = localStorage.getItem(VEHICLE_MAKE)?.toLowerCase() || "";
  //console logs vehicleMake 'Subaru'

  const handleLoanLeaseChange = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
    setLoanLeaseInfo({ ...loanLeaseInfo, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLoanLeaseDetailsInfo = loanLeaseDetailsInfo.map((field: LoanLeaseDetailFieldsType) =>
      field.name === e.currentTarget.name ? { ...field, value: e.currentTarget.value } : field
    );
    setLoanLeaseDetailsInfo(newLoanLeaseDetailsInfo);
  };

  //save to local storage
  const handleNextClick = () => {
    loanLeaseDetailsInfo.map((field: LoanLeaseDetailFieldsType) => localStorage.setItem(field.name, field.value));
    const newStatus: StepperOptions[] = stepperStatus.map((status: StepperOptions, index: number) => {
      if (index === STEP_LOAN_LEASE) {
        return StepperOptions.DONE;
      }
      if (index === STEP_UPLOAD_DOCUMENT) {
        return StepperOptions.CURRENT;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: newStatus }));
    navigate(ROUTE_DOCUMENTS);
  };

  const handlePreviousClick = () => {
    const previousStatus: StepperOptions[] = stepperStatus.map((status: StepperOptions, index: number) => {
      if (index === STEP_VEHICLE_INFO && stepperStatus[STEP_LOAN_LEASE] === StepperOptions.SKIPPED) {
        return StepperOptions.CURRENT;
      }
      if (index === STEP_TITLE_PAGE && status === StepperOptions.DONE) {
        return StepperOptions.CURRENT;
      }
      if (index === STEP_LOAN_LEASE) {
        return StepperOptions.NOT_STARTED;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: previousStatus }));
    navigate(ROUTE_TITLE);
  };

  return (
    <div className="text-center justify-center content-center w-screen">
      <Alert
        open={showTenDayHelperPopUp}
        onClose={closePopUp}
        bodyText={t("YOU_CAN_FIND_THIS_PAYOFF_LETTER")}
        headerText={t("WHERE_CAN_I_FIND_MY_TEN_DAY_PAYOFF_LETTER")}
        negativeText={t("CLOSE")}
        negativeAction={closePopUp}
      />

      <div className="text-center m-auto w-fit">
        <MultipleChoiceButtons
          orientation="horizontal"
          spacing="mr-2"
          onClick={handleLoanLeaseChange}
          name={"loanOrLease"}
          choiceArray={LoanLeaseInitialFields}
          selectedValue={loanLeaseInfo.loanOrLease}
          truthy="bg-sky-500 text-white"
          falsy=""
        />
      </div>
      {/* loan form */}
      {loanLeaseInfo.loanOrLease === "" ? null : loanLeaseInfo.loanOrLease === "lease" && brandsArray.includes(vehicleMake) ? (
        <div className="text-base text-center leading-relaxed text-gray-500 py-2 w-1/4 m-auto border-t-2 border-grey-300 border-dotted mt-2">
          <AlertTriangle />
          {`${t("CANT_BUY_MESSAGE")}`}
        </div>
      ) : (
        <div className="mt-4 align-middle text-center">
          {loanLeaseInfo.loanOrLease === "lease" ? (
            <h2 className="text-base m-2 font-bold pb-2"> {`${t("LEASE_COMPANY")}`}</h2>
          ) : (
            <h2 className="text-base m-2 font-bold pb-2"> {`${t("LOAN_INFORMATION")}`}</h2>
          )}
          {loanLeaseDetailsInfo.map((field: LoanLeaseDetailFieldsType) => (
            <div key={field.name}>
              <TextInput
                labelText={field.labelText}
                id={field.name}
                name={field.name}
                value={field.value}
                onChange={handleInputChange}
                errorMsg={""}
                icon={field.iconPosition && <HelperIcon className="ml-2" onClick={handleTenDayHelperClick} />}
                iconPosition={field.iconPosition}
              />
            </div>
          ))}
        </div>
      )}

      <ForwardBackwardButton
        backwardText={t("PREVIOUS")}
        forwardText={t("NEXT")}
        backwardOnClick={handlePreviousClick}
        forwardOnClick={handleNextClick}
      />
    </div>
  );
};

export default LoanLease;
