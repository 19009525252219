import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import puppy from "../../assets/puppy.jpg";
import BaseImage from "../../components/atoms/image/BaseImage";
import BaseParagraph from "../../components/atoms/typography/BaseParagraph";
import { clearState } from "../../redux/store";

const SuccessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  });

  return (
    <div className="pt-8 h-fit w-screen">
      <h2 className="text-lg text-center text-sky-500 font-roboto mt-4 sm:mb-8 md:mb-16 lg:mb-2 px-8 md:text-xl">{`${t(
        "SUBMISSION_CONFIRMATION"
      )}`}</h2>
      <BaseParagraph className="text-center text-gray-500 text-lg pt-4">{`${t("YOU_CAN_CLOSE_THIS_TAB_NOW")}`} </BaseParagraph>{" "}
      <div className="m-auto md:h-fit m:w-2/5 bottom-0 lg:h-1/2 lg:w-1/4 pt-16">
        <BaseImage src={puppy} alt="" className="bg-contain" />
      </div>
    </div>
  );
};

export default SuccessPage;
