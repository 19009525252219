import { DateInputType } from "../../../entities/inputs/DateInputType";
import BaseInput from "../../atoms/inputs/BaseInput";
import BaseLabel from "../../atoms/labels/BaseLabel";
import ErrorLine from "../../atoms/typography/ErrorLine";

/**
 * Date input component
 * 
 * Tips: Find out the exact input width for date in px and assign that to style props
 * 
 * @returns Div JSX element
 */
const DateInput = (props: DateInputType) => {
  return (
    <div className={props.divClasses}>
      <BaseLabel
        for={props.id}
        labelText={props.labelText}
        className={`${props.labelClasses} text-center block text-base text-sky-500 font-light my-2`}
      />
      <BaseInput
        id={props.id}
        name={props.name}
        className={`${props.inputClasses} block border-2 border-gray-200 w-56 md:w-64 rounded-md pl-2 text-base form-input m-auto`}
        onChange={props.onChange}
        type={"date"}
        value={props.value}
      />
      <ErrorLine errorMsg={props.errorMsg || ""} />
    </div>
  );
}

export default DateInput;