import { BaseButtonType } from "../../../entities/buttons/BaseButtonType";
import BaseButton from "./BaseButton";

/**
 * Typically used as Yes or No button
 * 
 * Must be used to create a molecule, defeat the purpose for usage like a single button 
 * 
 * @returns {JSX.Element} JSX.Element
 */
const ChoiceButton = (props: BaseButtonType): JSX.Element => {
  return (
    <BaseButton
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      className={`border-2 border-gray-200 min-w-[4rem] min-w-min text-base rounded-full ${props.className}`}
    >
      {props.children}
    </BaseButton>
  );
}

export default ChoiceButton;