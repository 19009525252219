import { useTranslation } from "react-i18next";

import logo from "../../../assets/logo.png";
import { SWICKARD_LOGO } from "../../../constants/constants";
import BaseImage from "../../atoms/image/BaseImage";
import BaseParagraph from "../../atoms/typography/BaseParagraph";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-8 h-auto flex flex-col items-center w-11/12 sm:w-full m-auto">
      <BaseImage src={logo} alt={SWICKARD_LOGO} className="h-16 my-2" />
      <BaseParagraph className="mt-2 font-semibold text-gray-500 text-base font-roboto text-center pt-4">
        {`${t("HEADER_DEALERSHIP_PREFIXES")}`} {`${t("SWICKARD_AUTO_GROUP")}`}
      </BaseParagraph>
    </div>
  );
};

export default Header;
