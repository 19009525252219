import { ChoiceObjectType, MultipleChoiceButtonsType } from "../../../entities/buttons/MultipleChoiceButtonsType";
import ChoiceButton from "../../atoms/buttons/ChoiceButton";

/**
 * Create list of clickable options with the input array values.
 *
 * @returns Div with an array of ChoiceButton
 */
const MultipleChoiceButtons = (props: MultipleChoiceButtonsType) => {
  return (
    <div className={`flex ${props.orientation === "horizontal" ? "flex-row" : "flex-col"}`}>
      {props.choiceArray.map((choice: ChoiceObjectType) => (
        <ChoiceButton
          key={choice.displayedText}
          name={props.name}
          onClick={props.onClick}
          value={choice.value}
          className={`${props.className} ${props.spacing} ${choice.value === props.selectedValue ? props.truthy : props.falsy}`}
        >
          {choice.displayedText}
        </ChoiceButton>
      ))}
    </div>
  );
};

export default MultipleChoiceButtons;
