import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";

import { axiosInstanceNoAuth } from "../../axios/init";
import ForwardButton from "../../components/atoms/buttons/ForwardButton";
import TextLink from "../../components/atoms/links/TextLink";
import ErrorLine from "../../components/atoms/typography/ErrorLine";
import EmailInput from "../../components/molecules/inputs/EmailInput";
import Alert from "../../components/molecules/notifications/Alert";
import { REGISTRATION_EMAIL, ROUTE_RESET_PASSWORD } from "../../constants/constants";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { validateEmailUtil } from "../../utils/validate-email/ValidateEmailUtil";

const RegisterPage = () => {
  const [registerEmail, setRegisterEmail] = useState<string>("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [registerEmailError, setRegisterEmailError] = useState<string | null>(null);
  const [registerErrorMsg, setRegisterErrorMsg] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("REGISTER_ACCOUNT"), subTitle: t("REGISTRATION_DESC") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterEmail(e.currentTarget.value);
    setRegisterEmailError(null);
  };

  const onCloseAlert = () => {
    setOpenAlert(false);
  };

  const onRegisterClick = () => {
    if (registerEmail === "") {
      setOpenAlert(true);
      setRegisterEmailError(t("REQUIRED"));
    } else if (validateEmailUtil(registerEmail, true) === "") {
      localStorage.setItem(REGISTRATION_EMAIL, registerEmail);
      axiosInstanceNoAuth
        .post("/api/auth/signup", { email: registerEmail })
        .then((response: AxiosResponse) => {
          if (response.data.status === 200) {
            navigate(ROUTE_RESET_PASSWORD);
          } else {
            setRegisterErrorMsg(response.data.message);
          }
        })
        .catch(() => {
          setRegisterErrorMsg(t("SOMETHING_WENT_WRONG"));
        });
    } else {
      setOpenAlert(true);
    }
  };

  return (
    <div>
      <Alert headerText={t("ERROR")} bodyText={t("CHECK_YOUR_INPUT")} open={openAlert} onClose={onCloseAlert} />

      <EmailInput
        id={"register"}
        name={"register"}
        divClasses={"flex items-center flex-col"}
        labelText={t("EMAIL")}
        onChange={onEmailChange}
        value={registerEmail}
        validateEmail
        errorMsg={registerEmailError}
        disabled={false}
      />

      <ForwardButton className="m-auto block mt-8" onClick={onRegisterClick}>
        {t("REGISTER")}
      </ForwardButton>

      <ErrorLine errorMsg={registerErrorMsg} className="m-auto flex items-center flex-col" />

      <div className="m-auto w-fit mt-12 md:mt-20 space-y-4 px-6 block flex flex-col text-center">
        {/* change send temp password route */}

        <TextLink destination={ROUTE_RESET_PASSWORD}>{t("RESET_PASSWORD_HERE")}</TextLink>
      </div>
    </div>
  );
};

export default RegisterPage;
