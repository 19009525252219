import { t } from "i18next";
import { ChoiceObjectType } from "../../entities/buttons/MultipleChoiceButtonsType";

export const AllPartiesAvailableInitialFields: ChoiceObjectType[] = [
  {
    displayedText: t("YES"),
    value: "Y"
  },
  {
    displayedText: t("NO"),
    value: "N"
  }
];
