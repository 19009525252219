import { t } from "i18next";
import { IM_NOT_SURE, NO, YES } from "../../constants/constants";
import { ChoiceObjectType } from "../../entities/buttons/MultipleChoiceButtonsType";

export const MultipleOwnerInitialFields: ChoiceObjectType[] = [
  {
    displayedText: t("YES"),
    value: YES
  },
  {
    displayedText: t("NO"),
    value: NO
  },
  {
    displayedText: t("IM_NOT_SURE"),
    value: IM_NOT_SURE
  }
];
