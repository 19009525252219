import { t } from "i18next";
import { CUST_CITY, CUST_EMAIL, CUST_FIRST_NAME, CUST_LAST_NAME, CUST_PHONE, CUST_STATE, CUST_STREET, CUST_ZIP } from "../../constants/constants";

export const CustomerInfoInitialFields = [
  {
    errorMessage: "",
    labelText: t("FIRST_NAME"),
    name: CUST_FIRST_NAME,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("LAST_NAME"),
    name: CUST_LAST_NAME,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("STREET_ADDRESS"),
    name: CUST_STREET,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("CITY"),
    name: CUST_CITY,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("STATE"),
    name: CUST_STATE,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("ZIP"),
    name: CUST_ZIP,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("EMAIL"),
    name: CUST_EMAIL,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("PHONE"),
    name: CUST_PHONE,
    value: ""
  }
];