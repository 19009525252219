import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import puppy from "../../assets/puppy.jpg";
import PredefinedButton from "../../components/atoms/buttons/PredefinedButton";
import { ROUTE_CUSTOMER_INFO, STEP_CUSTOMER_INFO } from "../../constants/constants";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";

const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  const goToCustomerPage = () => {
    // manage redux state of Stepper component
    const newStatus = stepperStatus.map((status, index) => {
      if (index === STEP_CUSTOMER_INFO) {
        return StepperOptions.CURRENT;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: newStatus }));

    navigate(ROUTE_CUSTOMER_INFO);
  };

  return (
    <div className="mt-8 align-middle font-poppins lg:columns-2 lg:w-1/2 lg:m-auto pt-3 lg:pt-20">
      <div className="text-center m-auto lg:left-20 relative">
        <PredefinedButton
          className="w-10/12 md:w-9/12 lg:w-3/5 lg:text-lg sm:text-xl py-5 md:py-8 mt-4 lg:mt-16"
          onClick={goToCustomerPage}
          variant={"blueGradient"}
          corner={"pill"}
        >
          {t("GET_STARTED")}
        </PredefinedButton>
      </div>
      <div className="sm:m-auto bottom-0 w-full md:w-1/2 lg:w-full">
        <img src={puppy} alt="" className="bg-cover" />
      </div>
    </div>
  );
};

export default LandingPage;
