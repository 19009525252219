import { ForwardBackwardButtonsType } from "../../../entities/buttons/ForwardBackwardButtonsType";
import BackwardButton from "../../atoms/buttons/BackwardButton";
import ForwardButton from "../../atoms/buttons/ForwardButton";

/**
 * Next and Previous page navigation buttons
 * 
 * @returns Div with buttons
 */
const ForwardBackwardButtons = (props: ForwardBackwardButtonsType) => {
  return (
    <div className="mt-8">
      <BackwardButton className="mr-4" onClick={props.backwardOnClick}>{props.backwardText}</BackwardButton>
      <ForwardButton onClick={props.forwardOnClick}>{props.forwardText}</ForwardButton>
    </div>
  );
};

export default ForwardBackwardButtons;
