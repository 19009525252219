import { createSlice } from "@reduxjs/toolkit";
import { PositionEnum } from "../../constants/positionEnum";

import { ToastVariant } from "../../constants/toastVariantEnum";
import { GlobalToastType } from "../../entities/redux/GlobalToastType";
import { ReduxActionType } from "../../entities/redux/ReduxActionType";

const initialState: GlobalToastType = {
  toastMsg: "",
  open: false,
  variant: ToastVariant.DEFAULT,
  toastDuration: undefined,
  position: PositionEnum.TOP_CENTER
};

export const GlobalToastSlice = createSlice({
  name: "globalToastSlice",
  initialState,
  reducers: {
    updateGlobalToastMsg: (state: GlobalToastType, action: ReduxActionType<GlobalToastType>) => {
      state.toastMsg = action.payload.toastMsg;
      state.open = action.payload.open;
      state.variant = action.payload.variant;
      state.toastDuration = action.payload.toastDuration || 3000;
      state.position = action.payload.position || PositionEnum.TOP_CENTER;
    },
    clearGlobalToastMsg: (state: GlobalToastType) => {
      return {
        ...state,
        toastMsg: "",
        open: false,
        toastDuration: undefined
      };
    }
  }
});

export const { updateGlobalToastMsg, clearGlobalToastMsg } = GlobalToastSlice.actions;
