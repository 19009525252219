import { BaseInputType } from "../../../entities/inputs/BaseInputType";

/**
 * Basic Input element
 * 
 * @returns input JSX element
 */
const BaseInput = (props: BaseInputType) => {
  return (
    <input
      id={props.id}
      name={props.name}
      type={props.type}
      className={props.className}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onChange={props.onChange}
      placeholder={props.placeholder}
      required={props.required}
      style={props.style}
      value={props.value}
    />
  );
};

export default BaseInput;
