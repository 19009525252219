export enum PositionEnum {
  TOP_LEFT,
  TOP_RIGHT,
  TOP_CENTER,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  BOTTOM_CENTER,
  LEFT_CENTER,
  RIGHT_CENTER,
  CENTER
}
