import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ForwardButton from "../../components/atoms/buttons/ForwardButton";
import TextInput from "../../components/molecules/inputs/TextInput";
import { ROUTE_VEHICLE, STEP_CUSTOMER_INFO, STEP_VEHICLE_INFO } from "../../constants/constants";
import { CustomerInfoFieldsType } from "../../entities/customer-info/CustomerInfoFieldsType";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";
import { CustomerInfoInitialFields } from "./CustomerInfoInitialFields";

const CustomerInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  const [customerInfoFields, setCustomerInfoFields] = useState<CustomerInfoFieldsType[]>(CustomerInfoInitialFields);

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("YOUR_INFORMATION"), subTitle: t("CUSTOMER_CONFIRM") }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCustomerInfoFields = customerInfoFields.map((field: CustomerInfoFieldsType) =>
      field.name === e.currentTarget.name ? { ...field, value: e.currentTarget.value } : field
    );

    setCustomerInfoFields(newCustomerInfoFields);
  };

  const nextClick = () => {
    customerInfoFields.map((field: CustomerInfoFieldsType) => localStorage.setItem(field.name, field.value));
    const newStatus = stepperStatus.map((status, index) => {
      if (index === STEP_CUSTOMER_INFO) {
        return StepperOptions.DONE;
      }
      if (index === STEP_VEHICLE_INFO) {
        return StepperOptions.CURRENT;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: newStatus }));
    navigate(ROUTE_VEHICLE);
  };

  return (
    <div className="items-center flex flex-col">
      {customerInfoFields.map((field: CustomerInfoFieldsType) => (
        <TextInput
          key={field.name}
          labelText={field.labelText}
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          errorMsg={field.errorMessage}
        />
      ))}

      <ForwardButton className="mt-8" onClick={nextClick}>
        {t("BUTTON_NEXT")}
      </ForwardButton>
    </div>
  );
};

export default CustomerInfo;
