import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";

import { axiosInstanceNoAuth } from "../../axios/init";
import ForwardButton from "../../components/atoms/buttons/ForwardButton";
import TextLink from "../../components/atoms/links/TextLink";
import ErrorLine from "../../components/atoms/typography/ErrorLine";
import EmailInput from "../../components/molecules/inputs/EmailInput";
import PasswordInput from "../../components/molecules/inputs/PasswordInput";
import Alert from "../../components/molecules/notifications/Alert";
import { ROUTE_GETTING_STARTED, ROUTE_REGISTER, ROUTE_RESET_PASSWORD } from "../../constants/constants";
import { updateAuthStatus } from "../../redux/slices/AuthSlice";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";

const LoginPage = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const [alertHeader, setAlertHeader] = useState<string>("");
  const [alertBody, setAlertBody] = useState<string>("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [loginEmailErrorMsg, setLoginEmailErrorMsg] = useState<string>("");
  const [loginPasswordErrorMsg, setLoginPasswordErrorMsg] = useState<string>("");
  const [loginErrorMsg, setLoginErrorMsg] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("SIGN_IN"), subTitle: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.currentTarget.value);
    setLoginEmailErrorMsg("");
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserPassword(e.currentTarget.value);
    setLoginPasswordErrorMsg("");
  };

  const onCloseAlert = () => {
    setOpenAlert(false);
  };

  const onSignOnClick = () => {
    if (userEmail !== "" && userPassword !== "") {
      axiosInstanceNoAuth
        .post("/api/auth/signin", { email: userEmail, password: userPassword })
        .then((response: AxiosResponse) => {
          if (response.data.status === 200) {
            dispatch(updateAuthStatus({ isAuth: true, accessToken: response.data.response }));
            navigate(ROUTE_GETTING_STARTED);
          } else {
            setLoginErrorMsg(t("INVALID_CREDENTIALS"));
          }
        })
        .catch(() => {
          setLoginErrorMsg(t("SOMETHING_WENT_WRONG"));
        });
    } else {
      setOpenAlert(true);
      setAlertHeader(t("ERROR"));
      setAlertBody(t("CHECK_YOUR_INPUT"));

      if (userEmail === "") {
        setLoginEmailErrorMsg(t("REQUIRED"));
      }

      if (userPassword === "") {
        setLoginPasswordErrorMsg(t("REQUIRED"));
      }
    }
  };

  return (
    <div>
      <Alert headerText={alertHeader} bodyText={alertBody} open={openAlert} onClose={onCloseAlert} />

      <EmailInput
        id={"userEmail"}
        name={"userEmail"}
        divClasses={"flex items-center flex-col"}
        labelText={t("EMAIL")}
        onChange={onEmailChange}
        value={userEmail}
        validateEmail
        errorMsg={loginEmailErrorMsg}
        disabled={false}
      />

      <PasswordInput
        id={"userPassword"}
        name={"userPassword"}
        divClasses={"flex items-center flex-col mt-3"}
        labelText={t("PASSWORD")}
        onChange={onPasswordChange}
        value={userPassword}
        errorMsg={loginPasswordErrorMsg}
      />

      <ForwardButton className="m-auto block mt-8" onClick={onSignOnClick}>
        {t("SIGN_IN")}
      </ForwardButton>

      <ErrorLine errorMsg={loginErrorMsg} className="m-auto flex items-center flex-col" />

      <div className="m-auto w-fit mt-12 md:mt-20 space-y-6 px-6 block flex flex-col text-center">
        <TextLink destination={ROUTE_REGISTER}>{t("NEED_ACCOUNT_QUESTION")}</TextLink>
        <TextLink destination={ROUTE_RESET_PASSWORD}>{t("RESET_PASSWORD_HERE")}</TextLink>
      </div>
    </div>
  );
};

export default LoginPage;
