import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import sadDog from "../../assets/sadDog.jpg";
import PredefinedButton from "../../components/atoms/buttons/PredefinedButton";
import BaseParagraph from "../../components/atoms/typography/BaseParagraph";
import { ROUTE_PREVIOUS_PAGE } from "../../constants/constants";

const Error404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goHome = () => {
    navigate(ROUTE_PREVIOUS_PAGE);
  };
  return (
    <div className=" items-center justify-center md:h-1/2 md:w-1/2 m-auto w-screen h-screen">
      <div className="md:pt-16">
        <div className="lg:gap-4 lg:flex sm:pt-8 lg:pt-0 m-auto lg:columns-2">
          {/* 404 error message */}
          <div className="flex flex-col items-center justify-center">
            <h1 className="font-bold text-blue-600 text-9xl">404</h1>
            <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">{`${t("PAGE_NOT_FOUND")}`}</p>
            <BaseParagraph className="mb-8 text-center text-gray-500 md:text-lg"> {`${t("PAGE_DOES_NOT_EXIST")}`}.</BaseParagraph>
            <PredefinedButton onClick={goHome} variant={"blueish"} corner={"default"}>
              {t("GO_BACK")}
            </PredefinedButton>
          </div>
          <div className="pt-8 lg:pt-16 lg:w-1/2 lg:h-1/2 m-auto ">
            <img src={sadDog} alt="" className="object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
