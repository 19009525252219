import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";

import { axiosInstance } from "../../axios/init";
import HelperIcon from "../../components/atoms/icons/HelperIcon";
import Accordion from "../../components/molecules/accordion/Accordion";
import ForwardBackwardButton from "../../components/molecules/buttons/ForwardBackwardButtons";
import ChooseFileItem from "../../components/molecules/choose-file-item/ChooseFileItem";
import Alert from "../../components/molecules/notifications/Alert";
import Toast from "../../components/molecules/notifications/Toast";
import { ACCOUNT_NUMBER, CAR_REGISTRATION, CUST_CITY, CUST_EMAIL, CUST_FIRST_NAME, CUST_LAST_NAME, CUST_PHONE, CUST_STATE, CUST_STREET, CUST_ZIP, DRIVER_LICENSE, FINANCIAL_INSTITUTION, LOAN_OR_LEASE, MISCELLANEOUS_DOCUMENT, MULTIPLE_OWNERS, ODOMETER, ODOMETER_IMAGE, OWN_VEHICLE, OWNER_AVAILABILITY, PURCHASE_PRICE, ROUTE_LOANLEASE, ROUTE_SUCCESS, ROUTE_TITLE, SECOND_OWNER_ADDRESS, SECOND_OWNER_CITY, SECOND_OWNER_EMAIL, SECOND_OWNER_FIRST_NAME, SECOND_OWNER_LAST_NAME, SECOND_OWNER_PHONE, SECOND_OWNER_STATE, SECOND_OWNER_ZIP, STEP_LOAN_LEASE, STEP_TITLE_PAGE, STEP_UPLOAD_DOCUMENT, TEN_DAY_PAYOFF_AMOUNT, TENDAY_PAYOFF_LETTER, THIRD_OWNER_ADDRESS, THIRD_OWNER_CITY, THIRD_OWNER_EMAIL, THIRD_OWNER_FIRST_NAME, THIRD_OWNER_LAST_NAME, THIRD_OWNER_PHONE, THIRD_OWNER_STATE, THIRD_OWNER_ZIP, TITLE_DATE_ISSUED, TITLE_IMAGE, TITLE_NUMBER, TITLE_PRESENT, VEHICLE_BODY, VEHICLE_MAKE, VEHICLE_MODEL, VEHICLE_VIN, VEHICLE_YEAR } from "../../constants/constants";
import { PositionEnum } from "../../constants/positionEnum";
import { ToastVariant } from "../../constants/toastVariantEnum";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { resetStepperStatus, updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";

const UploadDocumentsSubmitPage = () => {
  const [licenseAccordionOpen, setLicenseAccordionOpen] = useState<boolean>(false);
  const [tenDayAccordionOpen, setTenDayAccordionOpen] = useState<boolean>(false);
  const [miscAccordionOpen, setMiscAccordionOpen] = useState<boolean>(false);
  const [showTenDayHelperPopUp, setShowTenDayHelperPopUp] = useState<boolean>(false);
  const [driverLicenseFile, setDriverLicenseFile] = useState<File | null>(null);
  const [registrationFile, setRegistrationFile] = useState<File | null>(null);
  const [titleFile, setTitleFile] = useState<File | null>(null);
  const [odometerFile, setOdometerFile] = useState<File | null>(null);
  const [payoffLetterFile, setPayoffLetterFile] = useState<File | null>(null);
  const [miscFile, setMiscFile] = useState<File | null>(null);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("UPLOAD_DOCS"), subTitle: t("PLEASE_UPLOAD_MESSAGE") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccordionClick = (e: MouseEvent<HTMLButtonElement>) => {
    switch (e.currentTarget.id) {
      case "licenseAccordion":
        setLicenseAccordionOpen(!licenseAccordionOpen);
        break;
      case "tenDayAccordion":
        setTenDayAccordionOpen(!tenDayAccordionOpen);
        break;
      case "miscAccordion":
        setMiscAccordionOpen(!miscAccordionOpen);
        break;
      default:
        break;
    }
  };

  const handleTenDayHelperClick = () => {
    setShowTenDayHelperPopUp(!showTenDayHelperPopUp);
  };

  const closePopUp = () => {
    setShowTenDayHelperPopUp(false);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case "driverLicense":
        setDriverLicenseFile(e.target.files ? e.target.files[0] : null);
        break;
      case "registration":
        setRegistrationFile(e.target.files ? e.target.files[0] : null);
        break;
      case "titleFile":
        setTitleFile(e.target.files ? e.target.files[0] : null);
        break;
      case "pictureOfOdometer":
        setOdometerFile(e.target.files ? e.target.files[0] : null);
        break;
      case "tenDayPayoffLetter":
        setPayoffLetterFile(e.target.files ? e.target.files[0] : null);
        break;
      case "miscFile":
        setMiscFile(e.target.files ? e.target.files[0] : null);
        break;
      default:
        break;
    }
  };

  const handlePreviousClick = () => {
    const previousStatus: StepperOptions[] = stepperStatus.map((status: StepperOptions, index: number) => {
      if (index === STEP_TITLE_PAGE && stepperStatus[STEP_LOAN_LEASE] === StepperOptions.SKIPPED) {
        return StepperOptions.CURRENT;
      }
      if (index === STEP_LOAN_LEASE) {
        return StepperOptions.NOT_STARTED;
      }
      if (index === STEP_UPLOAD_DOCUMENT) {
        return StepperOptions.NOT_STARTED;
      }
      return status;
    });
    dispatch(updateStepperStatus({ status: previousStatus }));
    localStorage.getItem(OWN_VEHICLE) === "yes" ? navigate(ROUTE_TITLE) : navigate(ROUTE_LOANLEASE);
  };

  const onCloseToast = () => {
    setShowToast(false);
  };

  const handleSubmit = () => {
    const formData = new FormData();

    const today = new Date().toISOString().slice(0, 10);

    formData.append(CUST_FIRST_NAME, localStorage.getItem(CUST_FIRST_NAME) || "");
    formData.append(CUST_LAST_NAME, localStorage.getItem(CUST_LAST_NAME) || "");
    formData.append(CUST_STREET, localStorage.getItem(CUST_STREET) || "");
    formData.append(CUST_CITY, localStorage.getItem(CUST_CITY) || "");
    formData.append(CUST_STATE, localStorage.getItem(CUST_STATE) || "");
    formData.append(CUST_ZIP, localStorage.getItem(CUST_ZIP) || "");
    formData.append(CUST_EMAIL, localStorage.getItem(CUST_EMAIL) || "");
    formData.append(CUST_PHONE, localStorage.getItem(CUST_PHONE) || "");
    formData.append(VEHICLE_MAKE, localStorage.getItem(VEHICLE_MAKE) || "");
    formData.append(VEHICLE_MODEL, localStorage.getItem(VEHICLE_MODEL) || "");
    formData.append(VEHICLE_YEAR, localStorage.getItem(VEHICLE_YEAR) || "");
    formData.append(VEHICLE_BODY, localStorage.getItem(VEHICLE_BODY) || "body");
    formData.append(ODOMETER, localStorage.getItem(ODOMETER) || "");
    formData.append(VEHICLE_VIN, localStorage.getItem(VEHICLE_VIN) || "");
    formData.append(PURCHASE_PRICE, localStorage.getItem(PURCHASE_PRICE) || "");
    formData.append(TITLE_NUMBER, localStorage.getItem(TITLE_NUMBER) || "");
    formData.append(TITLE_DATE_ISSUED, localStorage.getItem(TITLE_DATE_ISSUED) || "");
    formData.append(SECOND_OWNER_FIRST_NAME, localStorage.getItem(SECOND_OWNER_FIRST_NAME) || "");
    formData.append(SECOND_OWNER_LAST_NAME, localStorage.getItem(SECOND_OWNER_LAST_NAME) || "");
    formData.append(SECOND_OWNER_ADDRESS, localStorage.getItem(SECOND_OWNER_ADDRESS) || "");
    formData.append(SECOND_OWNER_CITY, localStorage.getItem(SECOND_OWNER_CITY) || "");
    formData.append(SECOND_OWNER_STATE, localStorage.getItem(SECOND_OWNER_STATE) || "");
    formData.append(SECOND_OWNER_ZIP, localStorage.getItem(SECOND_OWNER_ZIP) || "");
    formData.append(SECOND_OWNER_EMAIL, localStorage.getItem(SECOND_OWNER_EMAIL) || "");
    formData.append(SECOND_OWNER_PHONE, localStorage.getItem(SECOND_OWNER_PHONE) || "");
    formData.append(THIRD_OWNER_FIRST_NAME, localStorage.getItem(THIRD_OWNER_FIRST_NAME) || "");
    formData.append(THIRD_OWNER_LAST_NAME, localStorage.getItem(THIRD_OWNER_LAST_NAME) || "");
    formData.append(THIRD_OWNER_ADDRESS, localStorage.getItem(THIRD_OWNER_ADDRESS) || "");
    formData.append(THIRD_OWNER_CITY, localStorage.getItem(THIRD_OWNER_CITY) || "");
    formData.append(THIRD_OWNER_STATE, localStorage.getItem(THIRD_OWNER_STATE) || "");
    formData.append(THIRD_OWNER_ZIP, localStorage.getItem(THIRD_OWNER_ZIP) || "");
    formData.append(THIRD_OWNER_EMAIL, localStorage.getItem(THIRD_OWNER_EMAIL) || "");
    formData.append(THIRD_OWNER_PHONE, localStorage.getItem(THIRD_OWNER_PHONE) || "");
    formData.append(OWN_VEHICLE, localStorage.getItem(OWN_VEHICLE) === "yes" ? "Y" : "N");
    formData.append(TITLE_PRESENT, localStorage.getItem(TITLE_PRESENT) || "");
    formData.append(MULTIPLE_OWNERS, localStorage.getItem(MULTIPLE_OWNERS) || "");
    formData.append(OWNER_AVAILABILITY, localStorage.getItem(OWNER_AVAILABILITY) || "");
    formData.append(LOAN_OR_LEASE, localStorage.getItem(LOAN_OR_LEASE) || "");
    formData.append(FINANCIAL_INSTITUTION, localStorage.getItem(FINANCIAL_INSTITUTION) || "");
    formData.append(ACCOUNT_NUMBER, localStorage.getItem(ACCOUNT_NUMBER) || "");
    formData.append(TEN_DAY_PAYOFF_AMOUNT, localStorage.getItem(TEN_DAY_PAYOFF_AMOUNT) || "0");
    driverLicenseFile &&
      formData.append(
        DRIVER_LICENSE,
        driverLicenseFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(CUST_LAST_NAME)}-driver-license-${today}.${driverLicenseFile?.type.slice(
          driverLicenseFile?.type.indexOf("/") + 1
        )}`
      );
    registrationFile &&
      formData.append(
        CAR_REGISTRATION,
        registrationFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(
          CUST_LAST_NAME
        )}-vehicle-registration-${today}.${registrationFile?.type.slice(registrationFile?.type.indexOf("/") + 1)}`
      );
    odometerFile &&
      formData.append(
        ODOMETER_IMAGE,
        odometerFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(CUST_LAST_NAME)}-odometer-${today}.${odometerFile?.type.slice(
          odometerFile?.type.indexOf("/") + 1
        )}`
      );
    payoffLetterFile &&
      formData.append(
        TENDAY_PAYOFF_LETTER,
        payoffLetterFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(CUST_LAST_NAME)}-payoff-letter-${today}.${payoffLetterFile?.type.slice(
          payoffLetterFile?.type.indexOf("/") + 1
        )}`
      );
    miscFile &&
      formData.append(
        MISCELLANEOUS_DOCUMENT,
        miscFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(CUST_LAST_NAME)}-misc-${today}.${miscFile?.type.slice(
          miscFile?.type.indexOf("/") + 1
        )}`
      );
    titleFile &&
      formData.append(
        TITLE_IMAGE,
        titleFile,
        `${localStorage.getItem(CUST_FIRST_NAME)}-${localStorage.getItem(CUST_LAST_NAME)}-vehicle-title-${today}.${titleFile?.type.slice(
          titleFile?.type.indexOf("/") + 1
        )}`
      );

    axiosInstance
      .post("/api/ico", formData)
      .then((response: AxiosResponse) => {
        if (response.data.status === 200) {
          navigate(ROUTE_SUCCESS);
          localStorage.clear();
          dispatch(resetStepperStatus());
        } else {
          setShowToast(true);
          setSubmitErrorMsg(t("ERROR"));
        }
      })
      .catch(() => "");
  };

  return (
    <div className="flex flex-col items-center m-auto">
      <Alert
        open={showTenDayHelperPopUp}
        onClose={closePopUp}
        bodyText={t("YOU_CAN_FIND_THIS_PAYOFF_LETTER")}
        headerText={t("WHERE_CAN_I_FIND_MY_TEN_DAY_PAYOFF_LETTER")}
        negativeText={t("CLOSE")}
        negativeAction={closePopUp}
      />

      <div className="items-center flex flex-col border w-4/5 md:w-3/5 lg:w-2/6 md:p-2 pb-12 text-sm md:text-base">
        <Accordion
          id="licenseAccordion"
          className=""
          open={licenseAccordionOpen}
          text={t("LICENSE_AND_VEHICLE")}
          textPosition={"center"}
          onClick={handleAccordionClick}
        >
          <div className="w-auto text-sm lg:text-base ">
            <ChooseFileItem labelText={t("DRIVERS_LICENSE")} name={"driverLicense"} onChange={handleFileUpload} errorMsg={""} />
            <ChooseFileItem labelText={t("REGISTRATION")} name={"registration"} onChange={handleFileUpload} errorMsg={""} />
            {localStorage.getItem(TITLE_PRESENT) === "yes" ? (
              <ChooseFileItem labelText={t("COPY_OF_TITLE")} name={"titleFile"} onChange={handleFileUpload} errorMsg={""} />
            ) : null}
            <ChooseFileItem labelText={t("PICTURE_OF_ODOMETER")} name={"pictureOfOdometer"} onChange={handleFileUpload} errorMsg={""} />
          </div>
        </Accordion>

        {
          // own vehicle, no ten day accordion
          localStorage.getItem(OWN_VEHICLE) === "yes" ? null : (
            <Accordion
              id="tenDayAccordion"
              className=""
              open={tenDayAccordionOpen}
              text={t("TEN_DAY_PAYOFF_LETTER")}
              textPosition={"center"}
              onClick={handleAccordionClick}
            >
              <div>
                <HelperIcon className="m-auto mb-3" onClick={handleTenDayHelperClick} />

                <ChooseFileItem labelText={t("TEN_DAY_PAYOFF_LETTER")} name={"tenDayPayoffLetter"} onChange={handleFileUpload} errorMsg={""} />
              </div>
            </Accordion>
          )
        }

        <Accordion
          id="miscAccordion"
          className=""
          open={miscAccordionOpen}
          text={t("MISCELLANEOUS_DOCUMENTS")}
          textPosition={"center"}
          onClick={handleAccordionClick}
        >
          <ChooseFileItem labelText={t("OTHER")} name={"miscFile"} onChange={handleFileUpload} errorMsg={""} />
        </Accordion>
      </div>

      <ForwardBackwardButton
        backwardText={t("PREVIOUS")}
        forwardText={t("SUBMIT")}
        backwardOnClick={handlePreviousClick}
        forwardOnClick={handleSubmit}
      />

      <Toast variant={ToastVariant.FAIL} position={PositionEnum.BOTTOM_CENTER} open={showToast} onClose={onCloseToast} animatedClose>
        {submitErrorMsg}
      </Toast>
    </div>
  );
};

export default UploadDocumentsSubmitPage;
