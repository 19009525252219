import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import { PRODUCTION } from "./constants/constants";

// internationalize framework for language translation
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === PRODUCTION ? false : true,
    fallbackLng: "en",
    resources: {
      en: {
        translation: en
      }
    }
  });

export default i18n;
