import dogs from "../../../assets/dogs.jpg";
import { ROUTE_CUSTOMER_INFO, ROUTE_DOCUMENTS, ROUTE_LOANLEASE, ROUTE_TITLE, ROUTE_VEHICLE } from "../../../constants/constants";
import useShowByRoute from "../../../custom-hooks/UseShowByRoute";
import BaseImage from "../../atoms/image/BaseImage";

const Footer = () => {
  const shouldShow: boolean = useShowByRoute([ROUTE_CUSTOMER_INFO, ROUTE_VEHICLE, ROUTE_TITLE, ROUTE_LOANLEASE, ROUTE_DOCUMENTS]);

  return shouldShow ? (
    <div className="sm:h-screen sm:w-screen sm:bottom-0 lg:h-1/3 lg:w-1/3 m-auto">
      <BaseImage src={dogs} alt="" className="text-center m-auto bottom-0" />
    </div>
  ) : null;
};

export default Footer;
