import { MouseEvent, useEffect, useState } from "react";

import { PositionEnum } from "../../../constants/positionEnum";
import { ToastVariant } from "../../../constants/toastVariantEnum";
import useFixedPosition from "../../../custom-hooks/UseFixedPosition";
import { ToastType } from "../../../entities/notifications/ToastType";
import BaseButton from "../../atoms/buttons/BaseButton";
import CrossIcon from "../../atoms/icons/CrossIcon";
import BaseParagraph from "../../atoms/typography/BaseParagraph";

/**
 * Four variants of Toasts:
 * 1) Success
 * 2) Fail
 * 3) Warning
 * 4) Default
 *
 * To enable animation for closing, use "animatedClose"
 * @props {boolean} animatedClose
 *
 * Other props
 * @props {ToastVariant} variant
 * @props {PositionEnum} position
 * @props {boolean} open
 * @props {Function} onCLose
 * @props {number} displayDuration
 *
 * Refer ToastType to see supported props
 */
const Toast = ({ variant = ToastVariant.DEFAULT, position = PositionEnum.BOTTOM_CENTER, displayDuration = 1500, ...props }: ToastType) => {
  const toastPosition = useFixedPosition(position);
  const [isHidden, setIsHidden] = useState<string>("hidden");

  useEffect(() => {
    if (props.open === true) {
      if (props.globalAction) {
        // start the animation to close toast
        hideToast(displayDuration);
        // trigger function of global toast, usually for resetting redux state
        props.globalAction();
      } else {
        setIsHidden("")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const onCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    hideToast(props.animatedClose ? displayDuration : 0);
    props.onClose && props.onClose(e);
  };

  // duration in ms, default to 2 seconds
  const hideToast = (duration: number) => {
    if (props.animatedClose) {
      setIsHidden("animate__fadeOut");
    } else {
      setIsHidden("");
    }

    setTimeout(() => {
      setIsHidden("hidden");
    }, duration * 1.4);
  };

  return (
    <div
      className={`flex fixed w-full ${toastPosition} 
      ${props.animatedClose ? "animate__animated" : ""}
      ${isHidden}
      `}
      style={{ animationDelay: `${displayDuration}ms` }}
    >
      <div
        id="toast"
        className={`flex items-center w-full sm:w-64 md:min-w-[20rem] md:max-w-xs px-5 py-3 text-gray-800 rounded-lg shadow ${
          variant === ToastVariant.SUCCESS
            ? "bg-green-200"
            : variant === ToastVariant.FAIL
            ? "bg-red-200"
            : variant === ToastVariant.WARNING
            ? "bg-orange-200"
            : "bg-white"
        }`}
        role="alert"
      >
        <BaseParagraph className="text-base font-normal mr-2">{props.children}</BaseParagraph>
        <BaseButton
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast"
          aria-label="Close"
          onClick={onCloseClick}
        >
          <CrossIcon />
        </BaseButton>
      </div>
    </div>
  );
};

export default Toast;
