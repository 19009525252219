import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BaseButton from "../../components/atoms/buttons/BaseButton";
import BlueSkyParagraph from "../../components/atoms/typography/BlueSkyParagraph";
import ForwardBackwardButton from "../../components/molecules/buttons/ForwardBackwardButtons";
import MultipleChoiceButtons from "../../components/molecules/buttons/MultipleChoiceButtons";
import DateInput from "../../components/molecules/inputs/DateInput";
import TextInput from "../../components/molecules/inputs/TextInput";
import { I_CANT_FIND_IT, IM_NOT_SURE, MULTIPLE_OWNERS, NO, OWN_VEHICLE, OWNER_AVAILABILITY, ROUTE_DOCUMENTS, ROUTE_LOANLEASE, ROUTE_VEHICLE, STEP_LOAN_LEASE, STEP_TITLE_PAGE, STEP_UPLOAD_DOCUMENT, STEP_VEHICLE_INFO, TITLE_DATE_ISSUED, TITLE_NUMBER, TITLE_PRESENT, YES } from "../../constants/constants";
import { StepperOptions } from "../../entities/redux/StepperStateType";
import { ExtraOwnerType } from "../../entities/title-page/ExtraOwnerType";
import { TitlePageType } from "../../entities/title-page/TitlePageType";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { updateStepperStatus } from "../../redux/slices/StepperSlice";
import { AppReduxState } from "../../redux/store";
import { AllPartiesAvailableInitialFields } from "./AllPartiesAvailableInitialFields";
import { MultipleOwnerInitialFields } from "./MultipleOwnerInitialFields";
import { OwnVehicleInitialFields } from "./OwnVehicleInitialFields";
import { SecondOwnerInitialFields } from "./SecondOwnerInitialFields";
import { ThirdOwnerInitialFields } from "./ThirdOwnerInitialFields";
import { TitlePresentInitialFields } from "./TitlePresentInitialFields";

const TitlePage = () => {
  const [titleInfo, setTitleInfo] = useState<TitlePageType>({
    ownVehicle: "",
    titlePresent: "",
    titleNumber: "",
    titleIssueDate: "",
    multipleOwners: "",
    thirdOwner: false,
    ownerAvailability: ""
  });

  const [secondOwnerFields, setSecondOwnerFields] = useState<ExtraOwnerType[]>(SecondOwnerInitialFields);
  const [thirdOwnerFields, setThirdOwnerFields] = useState<ExtraOwnerType[]>(ThirdOwnerInitialFields);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("TITLE_INFORMATION"), subTitle: t("TITLE_TEXT") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleState = (e: MouseEvent<HTMLButtonElement>) => {
    setTitleInfo({ ...titleInfo, [e.currentTarget.name]: !titleInfo.thirdOwner });
  };

  const handleTitleInfoChange = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
    setTitleInfo({ ...titleInfo, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSecondOwnerInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondOwnerFields(
      secondOwnerFields.map((field: ExtraOwnerType) => (field.name === e.currentTarget.name ? { ...field, value: e.currentTarget.value } : field))
    );
  };

  const handleThirdOwnerInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setThirdOwnerFields(
      thirdOwnerFields.map((field: ExtraOwnerType) => (field.name === e.currentTarget.name ? { ...field, value: e.currentTarget.value } : field))
    );
  };

  const handlePreviousClick = () => {
    const previousStatus = stepperStatus.map((status, index) => {
      if (index === STEP_TITLE_PAGE) {
        return StepperOptions.NOT_STARTED;
      }
      if (index === STEP_VEHICLE_INFO) {
        return StepperOptions.CURRENT;
      }
      return status;
    });

    dispatch(updateStepperStatus({ status: previousStatus }));
    navigate(ROUTE_VEHICLE);
  };

  const handleNextClick = () => {
    localStorage.setItem(OWN_VEHICLE, titleInfo.ownVehicle);
    localStorage.setItem(TITLE_PRESENT, titleInfo.titlePresent);
    localStorage.setItem(TITLE_NUMBER, titleInfo.titleNumber);
    localStorage.setItem(TITLE_DATE_ISSUED, titleInfo.titleIssueDate);
    localStorage.setItem(MULTIPLE_OWNERS, titleInfo.multipleOwners);
    localStorage.setItem(OWNER_AVAILABILITY, titleInfo.ownerAvailability);

    secondOwnerFields.map((field: ExtraOwnerType) => localStorage.setItem(field.name, field.value));
    thirdOwnerFields.map((field: ExtraOwnerType) => localStorage.setItem(field.name, field.value));

    titleInfo.ownVehicle === YES ? navigate(ROUTE_DOCUMENTS) : navigate(ROUTE_LOANLEASE);

    let hasSkipped: boolean = false;

    const newStatus = stepperStatus.map((status, index) => {
      if (index === STEP_TITLE_PAGE) {
        return StepperOptions.DONE;
      }
      if (index === STEP_LOAN_LEASE) {
        if (titleInfo.ownVehicle === YES) {
          hasSkipped = true;
          return StepperOptions.SKIPPED;
        } else {
          return StepperOptions.CURRENT;
        }
      }
      if (hasSkipped && index === STEP_UPLOAD_DOCUMENT) {
        return StepperOptions.CURRENT;
      }
      return status;
    });

    dispatch(updateStepperStatus({ status: newStatus }));
  };

  return (
    <div className="align-middle text-center w-screen">
      {/* first section, do you own the vehicle? */}
      <div className="border-t-2 border-grey-300 border-dotted mt-2">
        <h2 className="text-sm mt-2 text-center content-center pb-2">{`${t("VEHICLE_OWNERSHIP_QUESTION")}`}</h2>
        <div className="mb-2 py-2 flex flex-row justify-center h-min">
          <MultipleChoiceButtons
            orientation="horizontal"
            spacing="mr-2"
            onClick={handleTitleInfoChange}
            name={"ownVehicle"}
            choiceArray={OwnVehicleInitialFields}
            selectedValue={titleInfo.ownVehicle}
            truthy="bg-sky-500 text-white"
            falsy=""
          />
        </div>
        {/* Do you have your title?? */}
        {titleInfo.ownVehicle === YES ? (
          <div className="border-t-2 border-grey-300 border-dotted ">
            <h2 className="text-sm mt-2 text-center content-center pb-2">{`${t("TITLE_HAVE_QUESTION")}`}</h2>
            <div className="mb-2 py-2 flex flex-row justify-center h-min">
              <MultipleChoiceButtons
                orientation="horizontal"
                spacing="mr-2"
                onClick={handleTitleInfoChange}
                name={"titlePresent"}
                choiceArray={TitlePresentInitialFields}
                selectedValue={titleInfo.titlePresent}
                truthy="bg-sky-500 text-white"
                falsy=""
                className={`inline-block px-5`}
              />
            </div>
            {/* enter title information */}
            {titleInfo.titlePresent === YES ? (
              <div className=" align-middle text-center border-t-2 border-grey-300 border-dotted pb-4">
                <h2 className="text-sm m-2">{`${t("ENTER_TITLE_INFO")}`} </h2>
                <div className="pb-4">
                  <TextInput
                    id={"titleNumber"}
                    name={"titleNumber"}
                    labelText={t("TITLE_NUMBER")}
                    labelClasses={"text-sm text-sky-500 font-light"}
                    value={titleInfo.titleNumber}
                    onChange={handleTitleInfoChange}
                    errorMsg={""}
                  />

                  <DateInput
                    id={"titleIssueDate"}
                    name={"titleIssueDate"}
                    divClasses={"mb-4"}
                    labelText={t("TITLE_ISSUE_DATE")}
                    labelClasses={"text-sm text-sky-500 font-light"}
                    value={titleInfo.titleIssueDate}
                    onChange={handleTitleInfoChange}
                    errorMsg={""}
                  />
                </div>
                {/* multiple owners? */}
                <div className="border-t-2 border-grey-300 border-dotted">
                  <h2 className="text-sm mt-2 text-center content-center pb-2">{`${t("MULTIPLE_OWNERS_QUESTION")}`}</h2>
                  <div className="mb-2 py-2 flex flex-row justify-center h-min">
                    <MultipleChoiceButtons
                      orientation="horizontal"
                      spacing="mr-2"
                      onClick={handleTitleInfoChange}
                      name={"multipleOwners"}
                      choiceArray={MultipleOwnerInitialFields}
                      selectedValue={titleInfo.multipleOwners}
                      truthy="bg-sky-500 text-white"
                      falsy=""
                      className={`inline-block px-5`}
                    />
                  </div>
                  {/* are there multiple owners? */}
                  {titleInfo.multipleOwners === YES ? (
                    <div className="mt-4 align-middle text-center border-t-2 border-grey-300 border-dotted mt-2">
                      <h2 className="text-sm pb-4 font-Poppins w-1/2 text-center m-auto mt-2"> {`${t("ENTER_MORE_OWNERS")}`}</h2>
                      <div className="inline-grid md:grid-cols-2 gap-x-6 m-auto">
                        {secondOwnerFields.map((field: ExtraOwnerType) => (
                          <TextInput
                            key={field.name}
                            id={field.name}
                            errorMsg={field.errorMessage}
                            labelText={field.labelText}
                            name={field.name}
                            value={field.value}
                            onChange={handleSecondOwnerInfoChange}
                          />
                        ))}
                      </div>

                      {/* add third owner information */}
                      <BaseButton onClick={handleToggleState} name="thirdOwner" className="pt-2 text-xl hover:font-bold block m-auto">
                        +
                      </BaseButton>

                      {titleInfo.thirdOwner === true ? (
                        <div className="mt-4 align-middle text-center border-t-2 border-grey-300 border-dotted mt-2">
                          <h2 className="text-sm pb-4 font-Poppins w-1/2 text-center m-auto mt-2"> {`${t("ADD_EXTRA_OWNER")}`}</h2>
                          <div className="inline-grid md:grid-cols-2 gap-x-6 m-auto">
                            {thirdOwnerFields.map((field: ExtraOwnerType) => (
                              <TextInput
                                key={field.name}
                                id={field.name}
                                errorMsg={field.errorMessage}
                                labelText={field.labelText}
                                name={field.name}
                                value={field.value}
                                onChange={handleThirdOwnerInfoChange}
                              />
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : titleInfo.multipleOwners === NO ? null : titleInfo.multipleOwners === IM_NOT_SURE ? (
                    <BlueSkyParagraph>{`${t("ALL_PARTIES_MUST_SIGN_NOTE")}`}</BlueSkyParagraph>
                  ) : (
                    ""
                  )}
                </div>

                {/* fourth section, are all parties available to sign? */}
                <div className="border-t-2 border-grey-300 border-dotted">
                  <h2 className="text-sm mt-2 text-center content-center">{`${t("ALL_AVAILABILITY")}`}</h2>
                  <div className="mb-2 py-2 flex flex-row justify-center h-min">
                    <MultipleChoiceButtons
                      orientation="horizontal"
                      spacing="mr-2"
                      onClick={handleTitleInfoChange}
                      name={"ownerAvailability"}
                      choiceArray={AllPartiesAvailableInitialFields}
                      selectedValue={titleInfo.ownerAvailability}
                      truthy="bg-sky-500 text-white"
                      falsy=""
                    />
                  </div>
                  {titleInfo.ownerAvailability === "Y" ? (
                    <div></div>
                  ) : titleInfo.ownerAvailability === "N" ? (
                    <BlueSkyParagraph>{`${t("THATS_OK_CONTINUE")}`}</BlueSkyParagraph>
                  ) : (
                    <br />
                  )}
                </div>
              </div>
            ) : titleInfo.titlePresent === NO || titleInfo.titlePresent === I_CANT_FIND_IT ? (
              <BlueSkyParagraph>{`${t("THATS_OK_CONTINUE")}`}</BlueSkyParagraph>
            ) : null}
          </div>
        ) : titleInfo.ownVehicle === NO ? (
          <BlueSkyParagraph>{`${t("PLEASE_CONTINUE")}`}</BlueSkyParagraph>
        ) : null}
      </div>

      <ForwardBackwardButton
        backwardText={t("PREVIOUS")}
        forwardText={t("NEXT")}
        backwardOnClick={handlePreviousClick}
        forwardOnClick={handleNextClick}
      />
    </div>
  );
};

export default TitlePage;
