import { t } from "i18next";

import { ACCOUNT_NUMBER, FINANCIAL_INSTITUTION, TEN_DAY_PAYOFF_AMOUNT } from "../../constants/constants";
import { LoanLeaseDetailFieldsType } from "../../entities/loan-lease-page/LoanLeaseDetailFieldsType";

export const LoanLeaseDetailInitialFields: LoanLeaseDetailFieldsType[] = [
  {
    errorMessage: "",
    labelText: t("FINANCIAL_INSTITUTION"),
    name: FINANCIAL_INSTITUTION,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("ACCOUNT_NUMBER"),
    name: ACCOUNT_NUMBER,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("TEN_DAY_PAYOFF"),
    name: TEN_DAY_PAYOFF_AMOUNT,
    value: "",
    iconPosition: "right"
  }
];
