import { t } from "i18next";
import { NO, YES } from "../../constants/constants";
import { ChoiceObjectType } from "../../entities/buttons/MultipleChoiceButtonsType";

export const OwnVehicleInitialFields: ChoiceObjectType[] = [
  {
    displayedText: t("YES"),
    value: YES
  },
  {
    displayedText: t("NO"),
    value: NO
  }
];
