import { useEffect, useState } from "react";
import { PositionEnum } from "../constants/positionEnum";

/**
 * Custom hooks to return the css to position components with css of position: fixed
 * 
 * Usage:
 * const position = useFixedPosition(position)
 * 
 * Refer to example: Toast component
 */
const useFixedPosition = (position: PositionEnum) => {
  const [fixedPosition, setFixedPosition] = useState<string>("");

  useEffect(() => {
    switch (position) {
      case PositionEnum.BOTTOM_CENTER:
        setFixedPosition("bottom-0 justify-center");
        break;
      case PositionEnum.BOTTOM_LEFT:
        setFixedPosition("bottom-0");
        break;
      case PositionEnum.BOTTOM_RIGHT:
        setFixedPosition("bottom-0 justify-end");
        break;
      case PositionEnum.CENTER:
        setFixedPosition("justify-center bottom-[50%]");
        break;
      case PositionEnum.LEFT_CENTER:
        setFixedPosition("bottom-[50%]");
        break;
      case PositionEnum.RIGHT_CENTER:
        setFixedPosition("justify-end bottom-[50%]");
        break;
      case PositionEnum.TOP_CENTER:
        setFixedPosition("top-0 justify-center");
        break;
      case PositionEnum.TOP_LEFT:
        setFixedPosition("top-0");
        break;
      case PositionEnum.TOP_RIGHT:
        setFixedPosition("top-0 justify-end");
        break;
    }
  }, [position]);

  return fixedPosition;
};

export default useFixedPosition;
