import { createSlice } from "@reduxjs/toolkit";
import { AuthStateType } from "../../entities/redux/AuthStateType";
import { ReduxActionType } from "../../entities/redux/ReduxActionType";

const initialState: AuthStateType = {
  isAuth: false,
  accessToken: ""
};

export const AuthSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    updateAuthStatus: (state: AuthStateType, action: ReduxActionType<AuthStateType>) => {
      (state.accessToken = action.payload.accessToken), (state.isAuth = action.payload.isAuth);
    },
    resetAuthStatus: () => {
      return {
        ...initialState
      };
    }
  }
});

export const { updateAuthStatus, resetAuthStatus } = AuthSlice.actions;
