import { t } from "i18next";

import { LEASE, LOAN } from "../../constants/constants";
import { ChoiceObjectType } from "../../entities/buttons/MultipleChoiceButtonsType";

export const LoanLeaseInitialFields: ChoiceObjectType[] = [
  {
    displayedText: t("LOAN"),
    value: LOAN
  },
  {
    displayedText: t("LEASE"),
    value: LEASE
  }
];
