import { HelperIconType } from "../../../entities/icons/HelperIconType";

const HelperIcon = (props: HelperIconType) => {
  return (
    <svg
      className={`w-6 cursor-pointer ${props.className}`}
      fill="none"
      stroke={`${props.color ? props.color : "#3498ff"}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
  );
};

export default HelperIcon;
