import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTE_UNAUTHORIZED } from "../constants/constants";
import { AppReduxState } from "../redux/store";

/**
 * Redirect app to unauthorize page if user is unauthorized to access certain pages
 */
const PrivateRoute = () => {
  const authStatus = useSelector<AppReduxState, boolean>((state: AppReduxState) => state.authReducer.isAuth);

  return authStatus ? <Outlet /> : <Navigate to={ROUTE_UNAUTHORIZED} />;
};

export default PrivateRoute;
