import { createSlice } from "@reduxjs/toolkit";
import { ReduxActionType } from "../../entities/redux/ReduxActionType";
import { SectionHeaderStateType } from "../../entities/redux/SectionHeaderStateType";

const initialState: SectionHeaderStateType = {
  title: "",
  subTitle: ""
};

export const SectionHeaderSlice = createSlice({
  name: "sectionHeaderSlice",
  initialState,
  reducers: {
    updateSectionHeader: (state: SectionHeaderStateType, action: ReduxActionType<SectionHeaderStateType>) => {
      (state.title = action.payload.title), (state.subTitle = action.payload.subTitle);
    }
  }
});

export const { updateSectionHeader } = SectionHeaderSlice.actions;
