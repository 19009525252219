import { ChangeEvent, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { PasswordInputType } from "../../../entities/inputs/PasswordInputType";
import { validatePasswordUtil } from "../../../utils/ValidatePasswordUtil";
import BaseButton from "../../atoms/buttons/BaseButton";
import EyeOff from "../../atoms/icons/EyeOff";
import EyeOn from "../../atoms/icons/EyeOn";
import BaseInput from "../../atoms/inputs/BaseInput";
import BaseLabel from "../../atoms/labels/BaseLabel";
import ErrorLine from "../../atoms/typography/ErrorLine";

const PasswordInput = ({required = true, ...props}: PasswordInputType) => {
  const [validatorMsg, setValidatorMsg] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // validate input after 1.5 seconds of stopped typing
  const debounced = useDebouncedCallback((password: string) => {
    const msg = validatePasswordUtil(password, required);
    setValidatorMsg(msg);

    props.hasError && props.hasError(msg !== "");
  }, 1500);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);

    // check with validatePasswordUtil only if props.validatePassword is true
    if (props.validatePassword) {
      debounced(e.currentTarget.value);
    } else {
      props.hasError && props.hasError(false)
    }
  };

  const onShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${props.divClasses} relative w-56 md:w-64 m-auto`}>
      <BaseLabel
        for={props.id}
        labelText={props.labelText}
        className={`${props.labelClasses} text-center block text-base text-sky-500 font-light my-2`}
        icon={props.icon}
        iconPosition={props.iconPosition}
      />
      <div className="flex flex-row items-center justify-center">
        <BaseInput
          id={props.id}
          name={props.name}
          className={`${props.inputClasses} block border-2 border-gray-200 rounded-md w-56 md:w-64 text-sm pr-8`}
          onChange={handleOnChange}
          type={showPassword ? "text" : "password"}
          value={props.value}
        />
        <BaseButton className="absolute right-2" onClick={onShowPasswordClick}>
          {showPassword ? <EyeOff /> : <EyeOn />}
        </BaseButton>
      </div>
      <ErrorLine className="text-center" errorMsg={props.errorMsg || validatorMsg} />
    </div>
  );
};

export default PasswordInput;
