import { createSlice } from "@reduxjs/toolkit";

import { ReduxActionType } from "../../entities/redux/ReduxActionType";
import { StepperStateType } from "../../entities/redux/StepperStateType";

const initialState: StepperStateType = {
  status: []
};

export const StepperSlice = createSlice({
  name: "stepperSlice",
  initialState,
  reducers: {
    updateStepperStatus: (state: StepperStateType, action: ReduxActionType<StepperStateType>) => {
      state.status = action.payload.status;
    },
    resetStepperStatus: () => {
      return {
        ...initialState
      };
    }
  }
});

export const { updateStepperStatus, resetStepperStatus } = StepperSlice.actions;
