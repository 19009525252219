import { AlertType } from "../../../entities/notifications/AlertType";
import BaseButton from "../../atoms/buttons/BaseButton";
import CrossIcon from "../../atoms/icons/CrossIcon";

const Alert = (props: AlertType) => {
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className={`${
        props.open ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full bg-opacity-50 bg-gray-400`}
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto m-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <BaseButton
            onClick={props.onClose}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
          >
            <CrossIcon />
          </BaseButton>
          <div className="p-6 text-center pt-12">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">{props.headerText}</h3>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{props.bodyText}</h3>
            <BaseButton
              data-modal-toggle="popup-modal"
              onClick={props.positiveAction}
              className={`text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 ${
                !props.positiveText && "hidden"
              }`}
            >
              {props.positiveText}
            </BaseButton>
            <BaseButton
              data-modal-toggle="popup-modal"
              onClick={props.negativeAction}
              className={`text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 ${
                !props.negativeText && "hidden"
              }`}
            >
              {props.negativeText}
            </BaseButton>
            <BaseButton
              data-modal-toggle="popup-modal"
              onClick={props.neutralAction}
              className={`text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 ml-2 ${
                !props.neutralText && "hidden"
              }`}
            >
              {props.neutralText}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
