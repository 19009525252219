import { Action, combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, Persistor, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOGOUT } from "../constants/constants";

import { AuthSlice } from "./slices/AuthSlice";
import { GlobalToastSlice } from "./slices/GlobalToastSlice";
import { SectionHeaderSlice } from "./slices/SectionHeaderSlice";
import { StepperSlice } from "./slices/StepperSlice";

const appPersistConfig = {
  key: "appRedux",
  storage
};

const appReducers = combineReducers({
  sectionHeaderReducer: SectionHeaderSlice.reducer,
  authReducer: AuthSlice.reducer,
  stepperReducer: StepperSlice.reducer,
  globalToastReducer: GlobalToastSlice.reducer
});

export const clearState = createAction<undefined>(LOGOUT)

const rootReducer = (state: ReturnType<typeof appReducers> | undefined, action: Action<string>) => {
  if (action.type === clearState.type) {
    state = undefined;
  }

  return appReducers(state, action);
};

const persistedReducers = persistReducer(appPersistConfig, rootReducer);

export const appStore = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV === "production" ? false : true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

type AppReduxState = ReturnType<typeof appStore.getState>;

export const appPersistor: Persistor = persistStore(appStore);

export type { AppReduxState };
