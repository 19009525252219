import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import sadDog from "../../assets/sadDog.jpg";
import PredefinedButton from "../../components/atoms/buttons/PredefinedButton";
import BaseImage from "../../components/atoms/image/BaseImage";
import BaseParagraph from "../../components/atoms/typography/BaseParagraph";
import { ROUTE_LOGIN, ROUTE_REGISTER } from "../../constants/constants";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToRegister = () => {
    navigate(ROUTE_REGISTER);
  };

  const goToLogin = () => {
    navigate(ROUTE_LOGIN);
  };

  return (
    <div className=" items-center justify-center md:h-1/2 md:w-1/2 m-auto w-screen h-screen">
      <div className="md:pt-16">
        <div className="lg:gap-4 lg:flex sm:pt-8 lg:pt-0 m-auto lg:columns-2">
          {/* 404 error message */}
          <div className="flex flex-col items-center justify-center">
            <h1 className="font-bold text-blue-600 text-9xl">401</h1>
            <BaseParagraph className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl px-2">{`${t("UNAUTHORIZED")}`}</BaseParagraph>
            <BaseParagraph className="mb-8 text-center text-gray-500 md:text-lg px-2">{t("CLICK_THE_BUTTON_BELOW_TO_LOGIN_REGISTER")}</BaseParagraph>
            <div>
              <PredefinedButton className="mr-3" onClick={goToLogin} variant={"blueish"} corner={"default"}>
                {t("LOGIN_NOW")}
              </PredefinedButton>
              <PredefinedButton onClick={goToRegister} variant={"blueish"} corner={"default"}>
                {t("REGISTER_ACCOUNT")}
              </PredefinedButton>
            </div>
          </div>
          <div className="pt-8 lg:pt-16 lg:w-1/2 lg:h-1/2 m-auto ">
            <BaseImage src={sadDog} alt="" className="object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
