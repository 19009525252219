import { FileInputType } from "../../../entities/inputs/FileInputType";
import BaseInput from "../../atoms/inputs/BaseInput";
import BaseLabel from "../../atoms/labels/BaseLabel";
import ErrorLine from "../../atoms/typography/ErrorLine";

/**
 * File input component
 *
 * @returns Div JSX element
 */
const FileInput = (props: FileInputType) => {
  return (
    <div className="text-center">
      <BaseLabel for={props.name} className="block py-2 m-auto max-w-max md:max-w-none text-left cursor-pointer" labelText={props.labelText} />
      <BaseInput
        type="file"
        id={props.name}
        name={props.name}
        className="block m-auto w-auto md:w-full mt-2 text-sm text-gray-900 cursor-pointer"
        onChange={props.onChange}
      />

      <ErrorLine errorMsg={props.errorMsg || ""} />
    </div>
  );
};

export default FileInput;
