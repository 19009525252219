import { useSelector } from "react-redux";

import { ROUTE_CUSTOMER_INFO, ROUTE_DOCUMENTS, ROUTE_LOANLEASE, ROUTE_LOGIN, ROUTE_REGISTER, ROUTE_RESET_PASSWORD, ROUTE_TITLE, ROUTE_VEHICLE } from "../../../constants/constants";
import useShowByRoute from "../../../custom-hooks/UseShowByRoute";
import { SectionHeaderStateType } from "../../../entities/redux/SectionHeaderStateType";
import { AppReduxState } from "../../../redux/store";

/**
 * Section header with a title and subTitle
 * Renders texts from redux
 */

const SectionHeader = () => {
  const shouldShow: boolean = useShowByRoute([ROUTE_CUSTOMER_INFO, ROUTE_VEHICLE, ROUTE_TITLE, ROUTE_LOANLEASE, ROUTE_DOCUMENTS, ROUTE_REGISTER, ROUTE_RESET_PASSWORD, ROUTE_LOGIN]);

  const sectionHeaderObject = useSelector<AppReduxState, SectionHeaderStateType>((state: AppReduxState) => state.sectionHeaderReducer);

  return shouldShow ? (
    <div>
      <h1 className="text-lg border-b-2 border-gray-300 text-center font-roboto mb-4 mt-8">{sectionHeaderObject.title}</h1>
      <h2 className="text-base pb-4 font-light font-Poppins text-center px-2">{sectionHeaderObject.subTitle}</h2>
    </div>
  ) : null;
};

export default SectionHeader;
