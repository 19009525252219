import { useEffect, useState } from "react";
import { CaretType } from "../../../entities/icons/CaretType";

/**
 * Caret icon (4 directions)
 * 
 * @returns SVG JSX element
 */
const Caret = (props: CaretType) => {
  const [initialAngle, setInitialAngle] = useState<number>(0);

  useEffect(() => {
    switch (props.initialDirection) {
      case "up":
        setInitialAngle(180);
        break;
      case "down":
        setInitialAngle(0);
        break;
      case "left" || "right":
        setInitialAngle(90);
        break;
    }
  }, [props.initialDirection]);

  return (
    <svg
      data-accordion-icon
      className={`w-6 h-6 shrink-0 ${
        props.open
          ? props.initialDirection === "up"
            ? "rotate-0"
            : props.initialDirection === "down"
            ? "rotate-180"
            : props.initialDirection === "left"
            ? "-rotate-90"
            : "rotate-90"
          : props.initialDirection === "right"
          ? `-rotate-${initialAngle}`
          : `rotate-${initialAngle}`
      }
      `}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Caret;
