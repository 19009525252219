import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROUTE_CUSTOMER_INFO, ROUTE_DOCUMENTS, ROUTE_LOANLEASE, ROUTE_TITLE, ROUTE_VEHICLE } from "../../../constants/constants";
import useShowByRoute from "../../../custom-hooks/UseShowByRoute";
import { StepperOptions } from "../../../entities/redux/StepperStateType";
import { StepperType } from "../../../entities/stepper/StepperType";
import { updateStepperStatus } from "../../../redux/slices/StepperSlice";
import { AppReduxState } from "../../../redux/store";
import Checkmark from "../../atoms/icons/Checkmark";
import BaseParagraph from "../../atoms/typography/BaseParagraph";

/**
 * Horizontal stepper with a tooltip
 * Stepper status comes from redux
 * 
 * @returns Div JSX element
 */
const Stepper = (props: StepperType) => {
  const shouldShow: boolean = useShowByRoute([ROUTE_CUSTOMER_INFO, ROUTE_VEHICLE, ROUTE_TITLE, ROUTE_LOANLEASE, ROUTE_DOCUMENTS]);
  const stepperStatus = useSelector<AppReduxState, StepperOptions[]>((state: AppReduxState) => state.stepperReducer.status);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStepperStatus({ status: Array(props.steps).fill(StepperOptions.NOT_STARTED) }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @returns List of `<li>` component
   */
  const renderSteps = (): JSX.Element[] => {
    const stepList: JSX.Element[] = [];

    for (let i = 0; i < props.steps; i++) {
      stepList.push(
        <li
          className={`relative md:mb-5 sm:mb-3 mr-2 sm:mr-0`}
          key={`step${i}`}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={StepperOptions[stepperStatus[i]]?.toLocaleLowerCase().replaceAll("_", " ")}
        >
          <div className="flex items-center">
            <div
              className={`flex z-10 justify-center items-center w-8 h-8 ${
                stepperStatus[i] === StepperOptions.SKIPPED
                  ? "bg-blue-500 dark:bg-blue-900"
                  // ? "bg-yellow-200" This is being disabled at the moment, we want the skipped step to stay blue
                  : stepperStatus[i] === StepperOptions.DONE || stepperStatus[i] === StepperOptions.CURRENT
                  ? "bg-blue-500 dark:bg-blue-900"
                  : "ring-2 dark:ring-gray-200"
              } rounded-full ring-gray-200 shrink-0`}
            >
              {stepperStatus[i] === StepperOptions.DONE || stepperStatus[i] === StepperOptions.SKIPPED ? (
                <Checkmark/>
              ) : (
                <BaseParagraph className={`text-gray-900 ${stepperStatus[i] === StepperOptions.CURRENT ? "text-neutral-50" : ""}`}>{i + 1}</BaseParagraph>
              )}
            </div>
            {i < props.steps - 1 && (
              <div className="hidden sm:flex bg-gray-200 h-0.5 dark:bg-gray-700" style={{ paddingRight: `${10 - 0.15 * props.steps}vw` }}></div>
            )}
          </div>
        </li>
      );
    }
    return stepList;
  };

  return shouldShow ? (
    <div>
      <ol
        className={`flex flex-row flex-wrap pt-8 pb-2 m-auto
        ${props.row_position && props.row_position === "left" ? "justify-start" : props.row_position === "center" ? "justify-center" : "justify-end"}
        `}
      >
        {renderSteps()}
      </ol>
    </div>
  ) : null;
};

export default Stepper;
