// import { Navigate, Route, Routes } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Toast from "./components/molecules/notifications/Toast";

// import Toast from "./components/molecules/notifications/Toast";
import PrivateRoute from "./components/PrivateRoute";
import { ROUTE_CUSTOMER_INFO, ROUTE_DOCUMENTS, ROUTE_GETTING_STARTED, ROUTE_LOANLEASE, ROUTE_LOGIN, ROUTE_REGISTER, ROUTE_RESET_PASSWORD, ROUTE_SUCCESS, ROUTE_TITLE, ROUTE_UNAUTHORIZED, ROUTE_VEHICLE } from "./constants/constants";
import { GlobalToastType } from "./entities/redux/GlobalToastType";
// import { GlobalToastType } from "./entities/redux/GlobalToastType";
import CustomerInfo from "./pages/customer-info/CustomerInfo";
import Error404 from "./pages/error-404/Error404";
import LandingPage from "./pages/landing-page/LandingPage";
import LoanLease from "./pages/loan-lease-page/LoanLease";
import LoginPage from "./pages/login-page/LoginPage";
import Register from "./pages/register-page/RegisterPage";
import ResetPasswordPage from "./pages/reset-password-page/ResetPasswordPage";
import SuccessPage from "./pages/success-page/SuccessPage";
import TitlePage from "./pages/title-page/TitlePage";
import UnauthorizedPage from "./pages/unauthorize-page/UnauthorizedPage";
import UploadDocumentsSubmitPage from "./pages/upload-documets-submit-page/UploadDocumentsSubmitPage";
import VehicleInfo from "./pages/vehicle-info/VehicleInfo";

import { clearGlobalToastMsg } from "./redux/slices/GlobalToastSlice";
import { AppReduxState } from "./redux/store";

function App(): JSX.Element {
  const toastObject = useSelector<AppReduxState, GlobalToastType>((state: AppReduxState) => state.globalToastReducer);

  const dispatch = useDispatch();

  const onCloseToast = () => {
    dispatch(clearGlobalToastMsg());
  };

  const onClose = () => {
    setTimeout(() => onCloseToast(), toastObject.toastDuration || 3000);
  };

  return (
    <div>
      <Toast
        position={toastObject.position}
        variant={toastObject.variant}
        open={toastObject.open}
        onClose={onClose}
        globalAction={onClose}
        animatedClose
        displayDuration={2500}
      >
        {toastObject.toastMsg}
      </Toast>

      <Routes>
        {/* public routes */}
        <Route path="/" element={<Navigate to={ROUTE_LOGIN} replace />} />
        <Route path={ROUTE_REGISTER} element={<Register />} />
        <Route path={ROUTE_RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={ROUTE_LOGIN} element={<LoginPage />} />
        <Route path="*" element={<Error404 />} />
        <Route path={ROUTE_UNAUTHORIZED} element={<UnauthorizedPage />} />
        <Route path={ROUTE_SUCCESS} element={<SuccessPage />} />

        {/* private routes */}
        <Route element={<PrivateRoute />}>
          <Route path={ROUTE_GETTING_STARTED} element={<LandingPage />} />
          <Route path={ROUTE_CUSTOMER_INFO} element={<CustomerInfo />} />
          <Route path={ROUTE_VEHICLE} element={<VehicleInfo />} />
          <Route path={ROUTE_TITLE} element={<TitlePage />} />
          <Route path={ROUTE_LOANLEASE} element={<LoanLease />} />
          <Route path={ROUTE_DOCUMENTS} element={<UploadDocumentsSubmitPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
