import { ForwardButtonType } from "../../../entities/buttons/ForwardButtonType";
import BaseButton from "./BaseButton";

/**
 * Navigate to the next page Blue gradient button
 * 
 * Required props 
 * 1) onClick
 * 2) children (button text)
 * 
 * Optional props
 * 1) className
 * 2) value
 * 
 * @returns Button JSX element
 */
const ForwardButton = (props: ForwardButtonType) => {
  return (
    <BaseButton
      onClick={props.onClick}
      className={`bg-gradient-to-b from-sky-500 to-sky-400 hover:bg-gradient-to-t text-white text-base font-bold py-2 px-4 rounded min-w-min min-w-[7rem] ${props.className}`}
      value={props.value}
    >
      {props.children}
    </BaseButton>
  );
};

export default ForwardButton;
