import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 *
 * @param routeArray
 * @returns shouldShow: boolean
 *
 * Provide an array of route to show component at these given routes
 */

const useShowByRoute = (routeArray: string[]) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  const currentRoute: string = useLocation().pathname.trim();

  useEffect(() => {
    for (const route of routeArray) {
      if (currentRoute === route) {
        setShouldShow(true);
        return;
      }
    }

    setShouldShow(false);
  }, [routeArray, currentRoute]);

  return shouldShow;
};

export default useShowByRoute;
