import { t } from "i18next";
import isEmail from "validator/lib/isEmail";

export const validateEmailUtil = (email: string, required: boolean) => {
  if(email.length === 0 && required) {
    return t("REQUIRED")
  }
  
  if(!isEmail(email)) {
    return t("INVALID_EMAIL")
  }

  return ""
};
