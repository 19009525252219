import { t } from "i18next";
import { THIRD_OWNER_FIRST_NAME, THIRD_OWNER_LAST_NAME, THIRD_OWNER_ADDRESS, THIRD_OWNER_CITY, THIRD_OWNER_STATE, THIRD_OWNER_ZIP, THIRD_OWNER_EMAIL, THIRD_OWNER_PHONE } from "../../constants/constants";
import { ExtraOwnerType } from "../../entities/title-page/ExtraOwnerType";

export const ThirdOwnerInitialFields: ExtraOwnerType[] = [
  {
    errorMessage: "",
    labelText: t("FIRST_NAME"),
    name: THIRD_OWNER_FIRST_NAME,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("LAST_NAME"),
    name: THIRD_OWNER_LAST_NAME,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("STREET_ADDRESS"),
    name: THIRD_OWNER_ADDRESS,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("CITY"),
    name: THIRD_OWNER_CITY,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("STATE"),
    name: THIRD_OWNER_STATE,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("ZIP"),
    name: THIRD_OWNER_ZIP,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("EMAIL"),
    name: THIRD_OWNER_EMAIL,
    value: ""
  },
  {
    errorMessage: "",
    labelText: t("PHONE"),
    name: THIRD_OWNER_PHONE,
    value: ""
  }
];