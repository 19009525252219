import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { AxiosResponse } from "axios";

import { axiosInstance, axiosInstanceNoAuth } from "../../axios/init";
import ForwardButton from "../../components/atoms/buttons/ForwardButton";
import HelperIcon from "../../components/atoms/icons/HelperIcon";
import TextLink from "../../components/atoms/links/TextLink";
import ErrorLine from "../../components/atoms/typography/ErrorLine";
import EmailInput from "../../components/molecules/inputs/EmailInput";
import PasswordInput from "../../components/molecules/inputs/PasswordInput";
import Alert from "../../components/molecules/notifications/Alert";
import { REGISTRATION_EMAIL, ROUTE_LOGIN } from "../../constants/constants";
import { ToastVariant } from "../../constants/toastVariantEnum";
import { updateGlobalToastMsg } from "../../redux/slices/GlobalToastSlice";
import { updateSectionHeader } from "../../redux/slices/SectionHeaderSlice";
import { validateEmailUtil } from "../../utils/validate-email/ValidateEmailUtil";

const ResetPasswordPage = () => {
  const [temporaryPassword, setTemporaryPassword] = useState<string>("");
  const [tempPasswordErrorMsg, setTempPasswordErrorMsg] = useState<string>("");
  const [resetPassword, setResetPassword] = useState<string>("");
  const [resetPasswordErrorMsg, setResetPasswordErrorMsg] = useState<string>("");
  const [resetPasswordConfirm, setResetPasswordConfirm] = useState<string>("");
  const [resetPasswordConfirmErrorMsg, setResetPasswordConfirmErrorMsg] = useState<string>("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertHeader, setAlertHeader] = useState<string>("");
  const [alertBody, setAlertBody] = useState<string>("");
  const [registeredEmail, setRegisteredEmail] = useState<string>(localStorage.getItem(REGISTRATION_EMAIL) || "");
  const [resetPageEmailErrorMsg, setResetPageEmailErrorMsg] = useState<string>("");
  const [resetPasswordError, setResetPasswordError] = useState<string>("");

  const disableEmail = localStorage.getItem(REGISTRATION_EMAIL) !== null;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSectionHeader({ title: t("RESET_PASSWORD"), subTitle: t("THANK_YOU_FOR_REGISTERING_ACCOUNT") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTempPassWordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTemporaryPassword(e.currentTarget.value);
    setTempPasswordErrorMsg("");
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setResetPassword(e.currentTarget.value);
    setResetPasswordErrorMsg("");
  };

  // check if password matches after 1 sec of stopped typing of confirm password field
  const debounced = useDebouncedCallback((confirmPassword: string) => {
    if (resetPassword !== confirmPassword) {
      setResetPasswordConfirmErrorMsg(t("PASSWORD_MISMATCH"));
    } else {
      setResetPasswordConfirmErrorMsg("");
    }
  }, 1000);

  const onPasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setResetPasswordConfirm(e.currentTarget.value);
    setResetPasswordConfirmErrorMsg("");

    debounced(e.currentTarget.value);
  };

  const onHelperIconClick = () => {
    setOpenAlert(true);
    setAlertHeader(t("WHERE_TO_FIND_TEMP_PASSWORD"));
    setAlertBody(t("IT_IS_IN_THE_EMAIL"));
  };

  const onCloseAlert = () => {
    setOpenAlert(false);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisteredEmail(e.currentTarget.value);
    setResetPageEmailErrorMsg("");
  };

  const onResetPasswordClick = () => {
    if (registeredEmail === "") {
      setResetPageEmailErrorMsg(t("REQUIRED"));
    }

    if (temporaryPassword === "") {
      setTempPasswordErrorMsg(t("REQUIRED"));
    }

    if (resetPassword === "") {
      setResetPasswordErrorMsg(t("REQUIRED"));
    }

    if (resetPasswordConfirm === "") {
      setResetPasswordConfirmErrorMsg(t("REQUIRED"));
    }

    if (registeredEmail !== "" && temporaryPassword !== "" && resetPasswordConfirmErrorMsg === "") {
      axiosInstanceNoAuth
        .post("/api/auth/resetpassword", { email: registeredEmail, oldpassword: temporaryPassword, newpassword: resetPassword })
        .then((response: AxiosResponse) => {
          if (response.data.status === 200) {
            dispatch(updateGlobalToastMsg({ toastMsg: response.data.response, open: true, variant: ToastVariant.SUCCESS }));
            navigate(ROUTE_LOGIN);
            localStorage.clear();
          } else {
            setResetPasswordError(response.data.error);
          }
        })
        .catch(() => {
          setResetPasswordError(t("SOMETHING_WENT_WRONG"));
        });
    } else {
      setOpenAlert(true);
      setAlertHeader(t("ERROR"));
      setAlertBody(t("CHECK_YOUR_INPUT"));
    }
  };

  const onResendPassword = () => {
    if (validateEmailUtil(registeredEmail, true) === "") {
      axiosInstance
        .post("/api/ico/resendpassword", { email: registeredEmail })
        .then((response: AxiosResponse) => {
          if (response.data.status === 200) {
            dispatch(updateGlobalToastMsg({ toastMsg: t("RESET_PASSWORD_EMAIL"), open: true, variant: ToastVariant.SUCCESS }));
          } else {
            setResetPasswordError(response.data.error);
          }
        })
        .catch(() => {
          setResetPasswordError(t("SOMETHING_WENT_WRONG"));
        });
    } else {
      setOpenAlert(true);
      setAlertHeader("Invalid email address");
    }
  };

  return (
    <div className="pb-20 md:pb-0">
      <Alert headerText={alertHeader} bodyText={alertBody} open={openAlert} onClose={onCloseAlert} />

      <EmailInput
        id={"resetPageEmail"}
        name={"resetPageEmail"}
        divClasses={"flex items-center flex-col"}
        labelText={t("REGISTERED_EMAIL")}
        onChange={onEmailChange}
        value={registeredEmail}
        validateEmail
        errorMsg={resetPageEmailErrorMsg}
        disabled={disableEmail}
        inputClasses={disableEmail ? "bg-gray-200" : ""}
      />

      <PasswordInput
        id={"temporaryPassword"}
        name={"temporaryPassword"}
        divClasses={"flex items-center flex-col mt-4"}
        labelText={t("TEMPORARY_PASSWORD")}
        onChange={onTempPassWordChange}
        value={temporaryPassword}
        errorMsg={tempPasswordErrorMsg}
        icon={<HelperIcon className="ml-2" onClick={onHelperIconClick} />}
        iconPosition="right"
      />

      <PasswordInput
        id={"resetPassword"}
        name={"resetPassword"}
        divClasses={"flex items-center flex-col mt-4"}
        labelText={t("NEW_PASSWORD")}
        onChange={onPasswordChange}
        value={resetPassword}
        validatePassword
        errorMsg={resetPasswordErrorMsg}
      />

      <PasswordInput
        id={"resetPasswordConfirm"}
        name={"resetPasswordConfirm"}
        divClasses={"flex items-center flex-col mt-4"}
        labelText={t("CONFIRM_NEW_PASSWORD")}
        onChange={onPasswordConfirmChange}
        value={resetPasswordConfirm}
        errorMsg={resetPasswordConfirmErrorMsg}
      />

      <ErrorLine errorMsg={resetPasswordError} className="m-auto flex items-center flex-col" />

      <ForwardButton className="m-auto block mt-8" onClick={onResetPasswordClick}>
        {t("RESET_PASSWORD")}
      </ForwardButton>
      <div className="m-auto w-fit mt-8 space-y-4 px-6 block flex flex-col text-center">
        <TextLink destination={""} onClick={onResendPassword}>
          {t("SEND_NEW_TEMP_PASSWORD")}
        </TextLink>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
